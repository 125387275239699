import { TiPortal } from "@/TiPortal";
import { DruideModal } from "@yoshteq/druide-webcomponents";
import { EpaFileAndFolderInfo, EpaFileInfo, EpaFolder, RecordIdentifier } from "@yoshteq/ti365-ts-sdk";
import { LitElement, TemplateResult, css, html } from "lit";
import { customElement, query } from "lit/decorators.js";
import "../Components/MasterDetailLayout";
import MasterDetailLayout from "../Components/MasterDetailLayout";
import { StatusEventController } from "../Components/StatusEventController";
import { svgBackArrow, svgDelete, svgInfo, svgUpload } from "../Components/SvgConst";
import { EpaDeleteView } from "./EpaDeleteView";
import { DownloadDocument } from "./EpaDownloadDocument";
import "./EpaFileEntry";
import "./EpaFolderEntry";
import { EpaImageView } from "./EpaImageView";
import "./EpaMetaInfo";
import { EpaMioView } from "./EpaMioViewer";
import { EpaPdfView } from "./EpaPdfView";
import { EpaPlainTextView } from "./EpaPlainTextView";
import "./EpaUploadDocument";
import { EpaUploadDocumentView } from "./EpaUploadDocument";

@customElement("epa-overview")
export class EpaOverview extends LitElement {
    static styles = css`      `;
    status = new StatusEventController(this);
    folderMap: Map<EpaFolder, EpaFileInfo[]> = new Map();
    selectedFolder?: EpaFolder;
    selectedFile?: EpaFileInfo;
    reloadingEpaData = false;
    @query("master-detail-layout")
    layout!: MasterDetailLayout;
    content: Element | TemplateResult = html``;
    epaData: EpaFileAndFolderInfo[] = [];


    constructor(private recordIdentifier: RecordIdentifier) {
        super();

    }

    public updateEpaData(epaData: EpaFileAndFolderInfo[]) {
        this.epaData = epaData;
        this.folderMap = EpaFileAndFolderInfo.mapByFolder(epaData);
        this.reloadingEpaData = false;
        this.requestUpdate();
    }

    private reloadEpa = async () => {
        this.reloadingEpaData = true;
        try {
            this.showFolderOverview();
            const connector = await TiPortal.tiSession.getConnector();
            const phrService = connector.getPhrService();
            this.epaData = await phrService.listAll(this.recordIdentifier);
            this.updateEpaData(this.epaData);
        } finally {
            this.reloadingEpaData = false;
        }
    };

    private popstate = async (e: PopStateEvent) => {
        this.selectedFolder = undefined;

        if (e.state && e.state.folder) {
            for (const key of this.folderMap.keys()) {
                if (key.id === e.state.folder.id) {
                    this.selectedFolder = key;
                }
            }
        }

        this.selectedFile = e.state?.file;
        if (!this.selectedFile) {
            this.content = html``;
        }
        if (!this.layout.isNavigationVisible() && !this.selectedFile) {
            this.layout.showNavigation();
        } else if (this.selectedFolder) {
            history.back();
        }
        this.requestUpdate();
    };


    override connectedCallback(): void {
        super.connectedCallback();
        window.addEventListener("epa-reload", this.reloadEpa);
        window.addEventListener("popstate", this.popstate);
        this.reloadEpa();
    }
    override disconnectedCallback(): void {
        super.disconnectedCallback();
        window.removeEventListener("epa-reload", this.reloadEpa);
        window.removeEventListener("popstate", this.popstate);
    }

    protected override render(): TemplateResult {
        return html`
            <header-layout>
                <div slot="header-left">${this.renderBackButtons()}</div>
                <div slot="header-center">ePA von ${this.recordIdentifier.insurantId.insurantId}</div>
                <div slot="header-right">${this.renderActionButtons()}</div>
                <master-detail-layout slot="content">
                    <druide-list-menu slot="navigation"
                    style="width:100%; margin:0px;">
                        ${this.createList()}
                    </druide-list-menu>
                    ${this.content}
                </master-detail-layout>
            </header-layout>
            ${this.renderModals()}
            `;
    }

    private renderModals() {
        return html`
        <druide-modal id="meta-info-modal" >
            <druide-card label="ePA Dokumenten Attribute">
                <epa-meta-info .epaFileInfo=${this.selectedFile}></epa-meta-info>
                <druide-button slot="footer-left" cancel>Schließen</druide-button>
            </druide-card>
        </druide-modal>
        `;
    }

    private renderBackButtons() {
        return html`
        <druide-icon-button id="app-button" class="back-header" @click=${() => history.back()}>${svgBackArrow}</druide-icon-button>
        `;
    }

    private renderActionButtons() {
        if (this.selectedFile) {
            return html`
                <druide-icon-button @click=${this.showDocumentInformation}>${svgInfo}</druide-icon-button>
                <druide-icon-button @click=${this.showDeleteDocumentView}>${svgDelete}</druide-icon-button>
            `;
        } else {
            return html`<druide-icon-button @click=${this.showUpload}>${svgUpload}</druide-icon-button>`;
        }
    }
    private showDocumentInformation() {
        this.shadowRoot!.querySelector<DruideModal>("#meta-info-modal")!.show();
    }

    private showDeleteDocumentView() {
        this.setContent(new EpaDeleteView(this.selectedFile));
        this.content.addEventListener("cancel", () => this.openFile(this.selectedFile!));
        this.requestUpdate();
    }



    private showUpload() {
        this.selectedFile = undefined;
        this.setContent(new EpaUploadDocumentView(this.recordIdentifier));
    }

    private setContent(elm: Element): asserts this is this & { content: Element; } {
        elm.slot = "content";
        this.content = elm;
        this.requestUpdate();
        this.layout.showContent();
    }


    private createList() {
        const result: TemplateResult[] = [];
        if (this.reloadingEpaData) {
            return html`<loader-message style="curser:default;background:transparent;"><div style="margin:10px;"> ${this.status.message}</div></loader-message>`;
        }
        if (this.epaData.length === 0) {
            return html`<epa-simple-list-entry title="ePA ist leer"></epa-simple-list-entry>`;
        }

        if (!this.selectedFolder) {
            result.push(...this.createFolderOverview());
        } else {
            const files = this.folderMap.get(this.selectedFolder) || [];
            files.sort((f1, f2) => f2.lastUpdated.getTime() - f1.lastUpdated.getTime());
            files.map(file =>
                html` <epa-file-entry .epaFile=${file} @click=${() => this.openFile(file)}></epa-file-entry>`)
                .forEach((e) => result.push(e));
        }
        return result;
    }

    async openFile(fileInfo: EpaFileInfo) {
        if (this.selectedFile) {
            history.replaceState({ file: this.selectedFile, folder: this.selectedFolder }, "", "#epa/" + this.selectedFolder!.id + "/" + fileInfo.id);
        } else {
            history.pushState({ file: this.selectedFile, folder: this.selectedFolder }, "", "#epa/" + this.selectedFolder!.id + "/" + fileInfo.id);
        }
        this.selectedFile = fileInfo;
        if (fileInfo.mimeType === "application/pdf" && this.browserHasPDFSupport()) {
            this.setContent(new EpaPdfView(fileInfo));
            return;
        }
        if (fileInfo.mimeType === "text/plain") {
            this.setContent(new EpaPlainTextView(fileInfo));
            return;
        }
        if (fileInfo.mimeType === "image/jpeg"
            || fileInfo.mimeType === "image/png"
        ) {
            this.setContent(new EpaImageView(fileInfo));
            return;
        }

        if (fileInfo.mimeType === "application/fhir+xml") {
            this.setContent(new EpaMioView(fileInfo));
            return;
        }
        this.setContent(new DownloadDocument(fileInfo));
    }

    browserHasPDFSupport() {
        // see: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/pdfViewerEnabled
        return (navigator as any).pdfViewerEnabled || /iPad|iPhone|iPod/.test(navigator.userAgent);
    }

    createFolderOverview() {
        const folders = Array.from(this.folderMap.keys());
        folders.sort((f1, f2) => f2.lastUpdated.getTime() - f1.lastUpdated.getTime());
        return folders.map(folder =>
            html`<epa-folder-entry 
                .epaFolder=${folder} 
                @click=${() => this.selectFolder(folder)}></epa-folder-entry>`);

    }

    showFolderOverview() {
        this.selectedFolder = undefined;
        this.selectedFile = undefined;
        this.content = html``;
        this.requestUpdate();
        if (this.layout) {
            this.layout.showNavigation();
        }
    }

    selectFolder(folder: EpaFolder) {
        this.selectedFolder = folder;
        history.pushState({ file: this.selectedFile, folder: this.selectedFolder }, "", "#epa/" + this.selectedFolder.id);
        this.requestUpdate();
    }

}

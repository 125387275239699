import { css, html, LitElement, TemplateResult } from "lit";
import { customElement, query } from "lit/decorators.js";


@customElement("header-layout")
export class HeaderLayout extends LitElement {
    static styles = css`
        :host{
                --l-header-height: var(--header-height, 50px);
                --l-navigation-width: var(--navigation-width, 300px);
                --l-header-color:var(--header-color, #fff);
        }
        header {
            position: sticky;
            background-color: var(--l-header-color);
            top: 0;
            z-index: 1;
            height: var(--l-header-height);
            box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
            display:flex;
            align-items:center;
            justify-content:space-between;
            font-size:1.5em;

            > :first-child > * {
                flex: 1;
                overflow: hidden;
            }
        }

        .header-center {
            text-align: center;
        }
        .header-right {
            text-align: right;
        }
    `;

    @query("article")
    private content!: HTMLElement;

    public override connectedCallback(): void {
        super.connectedCallback();
    }

    public setContent(element: Element) {
        const removeList: Element[] = [];
        for (const child of this.children || []) {
            if (child.slot === "content") {
                removeList.push(child);
            }
        }
        removeList.forEach((e) => this.removeChild(e));
        element.slot = "content";
        this.appendChild(element);
    }

    protected override render(): TemplateResult {
        return html`
            <header>
                <slot name="header">
                    <div class="header-left">
                        <slot name="header-left"></slot>
                    </div>
                    <div class="header-center">
                        <slot name="header-center"></slot>
                    </div>
                    <div class="header-right">
                        <slot name="header-right"></slot>
                    </div>
                </slot>
            </header>
             <slot name="content"></slot>
        `;
    }
}
declare global {
    interface HTMLElementTagNameMap {
        "header-layout": HeaderLayout;
    }
}

import { css, html, LitElement, TemplateResult } from "lit";
import { customElement, property } from "lit/decorators.js";


@customElement("epa-simple-list-entry")
export class EpaSimpleListEntry extends LitElement {
    static styles = css`
            :host {
                box-sizing: border-box;
            }
            .container {
                box-sizing: border-box;
                color: var(--text-color);
                display: grid;
                padding: 0.5rem;
                grid-template-areas:
                    "title   title"
                    "detail-left   detail-right";
                grid-template-columns: 1fr min-content;
                grid-gap: 3px;
                transition: ease 200ms;
                cursor: default;
            }
            .container:hover {
                background-color: var(--background-color-hover);
                cursor: pointer;
            }

            .container > * {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .title {
                grid-area: title;
                font-size: 1.2em;
            }

            .detail-left, .detail-right  {
                font-size: 0.8em;
                opacity: 0.6;
            }
            .detail-left { grid-area: detail-left;}
            .detail-right { grid-area: detail-right;}
    `;

    @property()
    title = "";
    @property({ attribute: "detail-left" })
    detailLeft = "";
    @property({ attribute: "detail-right" })
    detailRight = "";

    protected override render(): TemplateResult {
        return html`
            <div class="container">
                <div class="title">${this.title}</div>
                <div class="detail-left">${this.detailLeft}</div>
                <div class="detail-right">${this.detailRight} </div>
            </div>`;
    }
}

import { TiPortal } from "@/TiPortal";
import { EpaFileInfo } from "@yoshteq/ti365-ts-sdk";
import { css, html, LitElement, TemplateResult } from "lit";
import { customElement, property } from "lit/decorators.js";
import { CssConst } from "../Components/CssConst";
import { StatusEventController } from "../Components/StatusEventController";


@customElement("epa-plain-text-view")
export class EpaPlainTextView extends LitElement {
    static styles = css`
    :host{
        --l-header-height: var(--header-height,  50px);
        font-size:0; // This is a hack that remove some spacing added around the iframe.
    }
    div{
        font-size:1rem;
    }

    `;
    status = new StatusEventController(this);
    @property({ type: Object })
    public epaFileInfo?: EpaFileInfo;
    text?: string;

    constructor(epaFileInfo?: EpaFileInfo) {
        super();
        if (epaFileInfo) {
            this.epaFileInfo = epaFileInfo;
        }
    }

    override connectedCallback(): void {
        super.connectedCallback();
        this.loadEpaData();
    }
    private async loadEpaData() {
        if (this.epaFileInfo) {
            const con = await TiPortal.tiSession.getConnector();
            const blob = await con.getPhrService().getDocumentBlob(this.epaFileInfo);
            const buff = await blob.arrayBuffer();
            this.text = new TextDecoder().decode(buff);
            this.requestUpdate();
        }

    }

    protected override render(): TemplateResult {
        if (this.text) {
            return html`
            <div slot="content" .style=${CssConst.flexCenter}>
                <druide-card slot="content" style="max-width:95%;" label=${this.epaFileInfo?.name || "Kein Titel"}>
                    <div style="white-space:pre-wrap">${this.text}</div>
                </druide-card>
            </div>
        `;
        } else {
            return html`
            <div slot="content" .style=${CssConst.flexCenter}>
                <druide-card slot="content" style="width:360px;" label="Lade ePA Dokument">
                    <loader-message>${this.status.message}</loader-message>
                </druide-card>
            </div>
        `;
        }

    }
}

import { css, html, LitElement, TemplateResult } from "lit";
import { customElement } from "lit/decorators.js";

@customElement("loader-message")
export class LoaderMessage extends LitElement {
    static styles = css`
    :host {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction:column;
        align-items: center;
        justify-content:center;
        overflow:hidden;
    }
    .text{
        display:inline-block;
    }  `;

    protected override render(): TemplateResult {
        return html`
        <loader-spinner></loader-spinner>
        <div class="text">
            <slot></slot>
        </div>
        `;
    }
}
declare global {
    interface HTMLElementTagNameMap {
        "loader-message": LoaderMessage;
    }
}

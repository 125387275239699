import { EpaFileInfo, EpaMimeTypeName } from "@yoshteq/ti365-ts-sdk";
import { LitElement, TemplateResult, css, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { byteToHuman } from "../Functions";


@customElement("epa-file-entry")
export class EpaFileEntry extends LitElement {
    static styles = css`
        :host {
            box-sizing: border-box;
            }
            .container {
                box-sizing: border-box;
                color: var(--text-color);
                display: grid;
                padding: 0.5rem;
                grid-template-areas:
                    "type   type    type  size"
                    "title  title   title title"
                    "author author  date  date";
                grid-template-columns: 1fr min-content min-content min-content;
                grid-gap: 3px;
                transition: ease 200ms;
                cursor: default;
            }
            .container:hover {
                background-color: var(--background-color-hover);
                cursor: pointer;
            }

            .container > * {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .title {
                grid-area: title;
                font-size: 1.2em;
            }

            .type, .size, .date , .author {
                font-size: 0.8em;
                opacity: 0.6;
            }

            .type { grid-area: type;}
            .size { grid-area: size;}
            .date { grid-area: date;}
            .author {grid-area: author;}
    `;

    @property({ type: Object })
    public epaFile?: EpaFileInfo;

    constructor(epaFile?: EpaFileInfo) {
        super();
        if (epaFile) {
            this.epaFile = epaFile;
        }
    }

    protected override render(): TemplateResult {
        if (!this.epaFile) {
            return html``;
        }
        const person = this.epaFile.author.person;
        return html`
            <div class="container">
                <div class="title">${this.epaFile.name}</div>
                <div class="author">${person.titel} ${person.vorname} ${person.nachname}</div>
                <div class="size">${byteToHuman(this.epaFile.size)} </div>
                <div class="type">${this.getFileType()}</div>
                <div class="date">${this.epaFile.lastUpdated.toLocaleString()} </div>
            </div>`;
    }

    protected getFileType() {
        const f = this.epaFile!;
        if (f.mimeType === "application/xml"
            || f.mimeType === "application/hl7-v3"
            || f.mimeType === "application/fhir+xml"
            || f.mimeType === "application/pkcs7-mime") {
            return f.format?.name;
        }
        return EpaMimeTypeName[f.mimeType];
    }


}

import { EpaFileInfo } from "@yoshteq/ti365-ts-sdk";
import { css, html, LitElement, TemplateResult } from "lit";
import { customElement, property } from "lit/decorators.js";
import "./EpaSimpleListEntry";


@customElement("epa-meta-info")
export class EpaMetaInfo extends LitElement {
    static styles = css`
        dt{
            font-weight:400;
        }
        dd{
            font-size:0.8em;
            margin-inline-start: 20px;
            margin-bottom: 5px;
        }
    `;
    @property({ type: Object })
    public epaFileInfo?: EpaFileInfo;

    constructor(epaFileInfo?: EpaFileInfo) {
        super();
        if (epaFileInfo) {
            this.epaFileInfo = epaFileInfo;
        }
    }

    protected override render(): TemplateResult {
        if (!this.epaFileInfo) {
            return html``;
        }
        const person = this.epaFileInfo.author.person;
        const institution = this.epaFileInfo.author.institution;

        return html`
            <dl>
                <dt>Titel</dt>
                <dd>${this.epaFileInfo.name}</dd>
                ${this.epaFileInfo.description ?
                html`
                <dt>Beschreibung</dt>
                <dd>${this.epaFileInfo.description}</dd>`
                : html``}                    
                <dt>Autor</dt>
                <dd>${person.titel} ${person.vorname} ${person.nachname}</dd>
                <dt>Einrichtung</dt>
                <dd>${institution.praxisName} (${this.epaFileInfo.healthcareFacilityType?.name})</dd>
                <dt>Grobe Klassifizierung</dt>
                <dd>${this.epaFileInfo.classCode?.name}</dd>
                <dt>Art des Dokuments</dt>
                <dd>${this.epaFileInfo.type?.name}</dd>
                <dt>Vertraulichkeitskennzeichnung</dt>
                <dd>${this.epaFileInfo.confidentiality?.name}</dd>
                <dt>Datei Format</dt>
                <dd>${this.epaFileInfo.format?.name}</dd>
                <dt>Art der Einrichtung</dt>
                <dd>${this.epaFileInfo.practiceSetting?.name}</dd>
            </dl>`;

    }
}

import { CssConst } from "@/Components/CssConst";
import "@/Components/ErrorDialog";
import ErrorDialog from "@/Components/ErrorDialog";
import { TiPortal } from "@/TiPortal";
import { DruideModal, DruideSelectField, DruideTextField, SelectOption } from "@yoshteq/druide-webcomponents";
import { AccountManagerErrorDetails, Card, CardType, KimClientError, KimSetupState, KimSetupWizard, PinStatus, PinType } from "@yoshteq/ti365-ts-sdk";
import { LitElement, TemplateResult, css, html } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { StatusEventController } from "../Components/StatusEventController";
import { EnterPinDialog } from "../EnterPinDialog";

@customElement("kim-setup-wizard")
export class KimSetupWizardView extends LitElement {

    static styles = css`[invisible] {
        display:none;
    }`;

    @state()
    private step: KimSetupState | "loading" | "done" = "loading";
    private status = new StatusEventController(this);
    private kimSetupWizard: KimSetupWizard;

    private onStateChanged = (state: KimSetupState) => {
        this.status.message = "";
        this.step = state;
    };
    referenceInputVisible: boolean = true;
    initialPasswortVisible: boolean = true;

    constructor() {
        super();
        this.kimSetupWizard = TiPortal.tiSession.kimService.createKimSetupWizard(this.onStateChanged);
    }

    override connectedCallback(): void {
        super.connectedCallback();
        this.referenceInputVisible = !!this.kimSetupWizard?.referenceId;
        this.initialPasswortVisible = !!this.kimSetupWizard?.initialPassword;
        this.kimSetupWizard.start().then(() => this.onStateChanged(this.kimSetupWizard.state));
    }

    @query("error-dialog")
    errorDialog!: ErrorDialog;

    protected override render(): TemplateResult {
        return html`
            ${this.renderStep()}
            <error-dialog></error-dialog>
        `;
    }

    private renderStep() {
        if (this.step === "loading") {
            return this.showLoadingScreen();
        } else if (this.step == "done") {
            return this.showKimSuccess();
        } else if (this.step === "enterKimAddress") {
            return this.showKimAddressInputStep();
        } else if (this.step === "registerAccount") {
            return this.showKimAddressRegisterStep();
        } else if (this.step === "setupAccount") {
            return this.showKimAddressSetupStep();
        } else if (this.step === "migrateTelematikId") {
            return this.showKimAddressMigrateStep();
        }
        console.error("Unknown View KiM SetupWizard Step:" + this.step);
        this.toServiceSelect();
        return html``;
    }

    @query("#kimAddress")
    private kimAddress!: DruideTextField;

    private showLoadingScreen() {
        return html`
            <div slot="content" style=${CssConst.flexCenter}>
                <druide-card slot="content" style="max-width:400px;min-width:300px;" label="KIM Einrichtung">               
                    <loader-message>${this.status.message}</loader-message>
                </druide-card>
            </div>`;
    }

    private showKimAddressInputStep() {
        return html`
            <div slot="content" style=${CssConst.flexCenter} @submit=${this.checkKimAddress}>
                <druide-card slot="content" style="max-width:400px;min-width:300px;" label="KIM Einrichtung">               
                    <div style="display:flex; flex-direction:column">
                        <div style="text-align:justify">
                            Dieser Assistent führt Sie durch die Einrichtung Ihrer KIM-Adresse. Welche KIM-Adresse möchten Sie einrichten?
                        </div>
                        <druide-text-field id="kimAddress" label="Adresse:" value="" required></druide-text-field>
                    </div>
                    <div style="font-size:0.8em">${this.status.message}</div>
                    <druide-button slot="footer-right" title="Weiter" submit></druide-button>
                    <druide-button slot="footer-left" title="zurück" @click=${this.toServiceSelect}  ></druide-button>
                </druide-card>
            </div>`;
    }

    private async checkKimAddress() {
        this.step = "loading";
        this.requestUpdate();
        const success = await this.kimSetupWizard.setKimAddress(this.kimAddress.value);
        if (!success) {
            this.step = "enterKimAddress";
        }
    }

    private toServiceSelect() {
        location.href = "#ti-service-select";
    }

    @state()
    private registerData: boolean = true;

    private showKimAddressRegisterStep() {
        return html`
            <div slot="content" style=${CssConst.flexCenter} @submit=${this.registerReactivateKimAddress}>
                <druide-card slot="content" style="max-width:400px;min-width:300px;" label="KIM Einrichtung">               
                    <div style="display:flex; flex-direction:column">
                        <div style="text-align:justify">
                            Ihre KIM-Adresse "${this.kimSetupWizard.kimAddress ?? ""}" wurde noch nicht registriert/ deaktiviert. 
                            Bitte geben Sie die notwendigen Registrierungsdaten ein.
                        </div>
                        <druide-checkbox label="Registrierungsdaten" .value=${this.registerData} @change=${this.onRegisterDataClicked}></druide-checkbox>
                        ${this.registerData ? this.renderRegistrationData() : undefined}
                        <druide-checkbox label="Reaktivierungsdaten" .value=${!this.registerData} @change=${this.onReactivateDataClicked}></druide-checkbox>
                        ${!this.registerData ? this.renderReactivateData() : undefined}
                    </div>
                    <div style="font-size:0.8em">${this.status.message}</div>
                    <druide-button slot="footer-right" title="Weiter" submit></druide-button>
                    <druide-button slot="footer-left" title="zurück" @click=${this.toMailInput}  ></druide-button>
                </druide-card>
            </div>            
            <druide-modal close-explicit id="hbaPinModal">
            </druide-modal>
       `;
    }

    private onRegisterDataClicked() {
        this.registerData = true;
    }

    private onReactivateDataClicked() {
        this.registerData = false;
    }

    @query("#referenceId")
    private referenceId!: DruideTextField;
    @query("#initialPasswort")
    private initialPasswort!: DruideTextField;
    @query("#cardSelectRegister")
    private cardSelectRegister!: DruideSelectField;
    @query("#newPasswort")
    private newPasswort!: DruideTextField;
    @query("#newPasswortRepeat")
    private newPasswortRepeat!: DruideTextField;
    @query("#hbaPinModal")
    hbaPinModal!: DruideModal;

    private renderRegistrationData() {
        return html`
            <div style="display:flex; flex-direction:column">
                <druide-text-field id="referenceId" label="Referenz ID:" value="${this.kimSetupWizard.referenceId ?? ""}" required ?invisible=${this.referenceInputVisible}></druide-text-field>
                <druide-text-field id="initialPasswort" label="Initiales Passwort:" value="${this.kimSetupWizard.initialPassword ?? ""}" required ?invisible=${this.initialPasswortVisible}></druide-text-field>
                <druide-select-field id="cardSelectRegister" label="Verschlüsselungs-Karte:" required .options=${this.kimSetupWizard.availableCards!.map(c => new SelectOption(c.handle, this.getCardName(c)))}></druide-select-field>
                <druide-text-field id="newPasswort"  type="password" label="Passwort:" value="" required></druide-text-field>
                <druide-text-field id="newPasswortRepeat"  type="password" label="Passwort Wiederholung:" value="" required></druide-text-field>
            </div>
        `;
    }

    @query("#cardSelectReactivate")
    private cardSelectReactivate!: DruideSelectField;
    @query("#passwordReactivate")
    private passwordReactivate!: DruideTextField;

    private renderReactivateData() {
        return html`
            <div style="display:flex; flex-direction:column">
                <druide-select-field id="cardSelectReactivate" label="Verschlüsselungs-Karte:" required .options=${this.kimSetupWizard.availableCards!.map(c => new SelectOption(c.handle, this.getCardName(c)))}></druide-select-field>
                <druide-text-field id="passwordReactivate"  type="password" label="Passwort:" value="" required></druide-text-field>
            </div>
        `;
    }

    private getCardName(card: Card) {
        let type = "";
        if (card.type === CardType.SMC_B) {
            type = "SMCB";
        } else if (card.type === CardType.HBA) {
            type = "HBA";
        }
        return card.cardHolderName + " ( " + type + " )";
    }

    private toMailInput() {
        this.onStateChanged("enterKimAddress");
        this.requestUpdate();
    }

    private async registerReactivateKimAddress() {
        if (this.registerData) {
            await this.prepareRegisterKimAddress();
        } else {
            await this.reactivateKimAccount();
        }
    }

    private async prepareRegisterKimAddress() {
        if (this.newPasswort.value.length < 8) {
            this.newPasswort.error = "Ihr Passwort sollte mindestens 8 Zeichen haben.";
            return;
        } else {
            this.newPasswort.error = undefined;
        }
        if (this.newPasswort.value !== this.newPasswortRepeat.value) {
            this.newPasswortRepeat.error = "Das Passwort stimmt nicht mit dem vorherigen überein.";
            return;
        } else {
            this.newPasswortRepeat.error = undefined;
        }

        const card = this.kimSetupWizard.availableCards!.find(c => c.handle === this.cardSelectRegister.value)!;
        await this.ensureUnlockedCard(card);

        this.step = "loading";
        this.requestUpdate();
        try {
            const success = await this.kimSetupWizard.registerKimAddress(card, this.referenceId.value, this.initialPasswort.value, this.newPasswort.value);
            if (success) {
                this.step = "done";
            } else {
                this.step = "registerAccount";
            }
        }
        catch (e) {
            this.step = "registerAccount";
            if (e instanceof KimClientError && e.code == "ACCOUNT_MANAGER_ERROR") {
                const detail = e.detail as AccountManagerErrorDetails;
                if (detail.code == "ADDRESS_NOT_FOUND") {
                    this.errorDialog.title = "Adresse nicht gefunden";
                    this.errorDialog.content = `Der Account konnte nicht angelegt werden, da die Adresse ${this.kimSetupWizard.kimAddress} nicht bekannt ist.`;
                    this.errorDialog.show();
                } else if (detail.code == "UNAUTHORIZED") {
                    this.errorDialog.title = "Ungültiger Registrierungscode";
                    this.errorDialog.content = `Der Account konnte nicht angelegt werden, da der Registrierungscode für die Adresse ${this.kimSetupWizard.kimAddress} falsch ist.`;
                    this.errorDialog.show();
                } else if (detail.code == "USERNAME_OR_PASSWORD_NOT_VALID") {
                    this.errorDialog.title = "Vorgaben nicht erfüllt";
                    let serverMessage = html``;
                    if (detail.bodyText.trim().length > 0) {
                        serverMessage = html`<br> Anbieter-Meldung: ${detail.bodyText}`;
                    }
                    this.errorDialog.content = html`KIM-Adresse oder Passwort entsprechen nicht den Regeln des Anbieters. ${serverMessage}`;
                    this.errorDialog.show();
                } else if (detail.code == "ACCOUNT_ALREADY_REGISTERED") {
                    this.errorDialog.title = "Account bereits registriert";
                    this.errorDialog.content = `Die Adresse ${this.kimSetupWizard.kimAddress} wird bereits bei einem bestehenden Account verwendet und kann nicht neu registriert werden.`;
                    this.errorDialog.show();
                } else {
                    // "BAD_REQUEST" | "UNSUPPORTED_KIM_VERSION" | "UNKNOWN"
                    this.errorDialog.title = "Unbekannter Account Manager Fehler";
                    this.errorDialog.content = `Für die Adresse ${this.kimSetupWizard.kimAddress} ist ein unbekannter Fehler aufgetreten.`;
                    this.errorDialog.show();
                }
            } else {
                this.errorDialog.title = "Unbekannter Fehler";
                this.errorDialog.content = `Für die Adresse ${this.kimSetupWizard.kimAddress} ist ein unbekannter Fehler aufgetreten.`;
                this.errorDialog.show();
            }
        } finally {
            this.requestUpdate();
        }
    }

    private async reactivateKimAccount() {
        const card = this.kimSetupWizard.availableCards!.find(c => c.handle === this.cardSelectReactivate.value)!;
        await this.ensureUnlockedCard(card);

        this.step = "loading";
        await this.kimSetupWizard.reactivateKimAddress(card, this.passwordReactivate.value);

        this.step = "done";
    }

    private showPinDialog(card: Card, pinType: PinType): Promise<void> {
        return new Promise((resolve, _reject) => {
            const epd = new EnterPinDialog();
            epd.card = card;
            epd.pinType = pinType;
            this.hbaPinModal.appendChild(epd);
            epd.addEventListener("pin-success", () => {
                this.hbaPinModal.hide();
                this.hbaPinModal.removeChild(epd);
                resolve();
            });
            this.hbaPinModal.show();
        });
    }

    private showKimAddressSetupStep() {
        return html`
            <div slot="content" style=${CssConst.flexCenter} @submit=${this.setupKimAddress}>
                <druide-card slot="content" style="max-width:400px;min-width:300px;" label="KIM Einrichtung">               
                    <div style="display:flex; flex-direction:column">
                        <div style="text-align:justify">
                            Ihre KIM-Adresse<br> ${this.kimSetupWizard.kimAddress ?? ""}<br> wurde bereits registriert. Bitte geben Sie Ihr Kennwort ein.
                        </div>
                        <druide-text-field id="newPasswort"  type="password" label="Passwort:" value="" required></druide-text-field>
                    </div>
                    <div  style="font-size:0.8em">${this.status.message}</div>
                    <druide-button slot="footer-right" title="Weiter" submit></druide-button>
                    <druide-button slot="footer-left" title="zurück" @click=${this.toMailInput}  ></druide-button>
                </druide-card>
            </div>  
            <druide-modal close-explicit id="hbaPinModal">
            </druide-modal>`;
    }

    private async setupKimAddress() {
        const card = this.kimSetupWizard.kimAccountCard!;
        const pinType: PinType = card.type === CardType.HBA ? "PIN.CH" : "PIN.SMC";
        const state = await TiPortal.tiSession.cardService.getPinStatus(card, pinType);

        if (state.pinStatus !== PinStatus.VERIFIED) {
            await this.showPinDialog(card, pinType);
        }

        this.step = "loading";
        this.requestUpdate();

        const success = await this.kimSetupWizard.setupKimAddress(this.newPasswort.value);
        if (success) {
            this.step = "done";
        } else {
            this.step = "setupAccount";
        }
        this.requestUpdate();
    }

    private showKimSuccess() {
        return html`
            <div slot="content" style=${CssConst.flexCenter}>
                <druide-card slot="content" style="max-width:400px;min-width:300px;" label="KIM Einrichtung">               
                    <div style="display:flex; flex-direction:column">
                        <div style="text-align:justify">
                            Einrichtung Ihres KIM Postfachs wurde erfolgreich abgeschlossen.
                        </div>
                    </div>
                    <div>${this.status.message}</div>
                    <druide-button slot="footer-right" title="Zum Postfach" @click=${this.toKimOverview} ></druide-button>
                </druide-card>
            </div>`;
    }

    private toKimOverview() {
        history.replaceState(undefined, "", "#ti-service-select");
        location.href = "#kim";
    }

    @query("#kimOtp")
    private kimOtp!: DruideTextField;

    private showKimAddressMigrateStep() {
        return html`
            <div slot="content" style=${CssConst.flexCenter} @submit=${this.migrateAddress}>
                <druide-card slot="content" style="max-width:400px;min-width:300px;" label="KIM Umzug">               
                    <div style="display:flex; flex-direction:column">
                        <div style="text-align:justify">
                            Diese Adresse ist auf eine SMC-B mit einer anderen Telematik-ID registriert. Sie können die Adresse umziehen, indem Sie über den alten Zugang ein Einmalpasswort anfordern.
                        </div>
                        <druide-select-field id="cardSelect" label="Verschlüsselungs-Karte:" required .options=${this.kimSetupWizard.availableCards!.map(c => new SelectOption(c.handle, this.getCardName(c)))}></druide-select-field>
                        <druide-text-field id="newPasswort"  type="password" label="Passwort:" value="" required></druide-text-field>
                        <druide-text-field id="kimOtp" label="Einmalpasswort:" value="" required></druide-text-field>
                    </div>
                    <div style="font-size:0.8em">${this.status.message}</div>
                    <druide-button slot="footer-right" title="Weiter" submit></druide-button>
                    <druide-button slot="footer-left" title="zurück" @click=${this.toServiceSelect}  ></druide-button>
                </druide-card>
            </div>`;
    }

    private async migrateAddress() {
        const card = this.kimSetupWizard.availableCards!.find(c => c.handle === this.cardSelectRegister.value)!;
        await this.ensureUnlockedCard(card);

        this.step = "loading";
        this.requestUpdate();
        const success = await this.kimSetupWizard.transferKimAddress(card, this.newPasswort.value, this.kimOtp.value);
        if (success) {
            this.step = "done";
        } else {
            this.step = "migrateTelematikId";
        }
        this.requestUpdate();
    }

    private async ensureUnlockedCard(card: Card) {
        const pinType: PinType = card.type === CardType.HBA ? "PIN.CH" : "PIN.SMC";
        const state = await TiPortal.tiSession.cardService.getPinStatus(card, pinType);

        if (state.pinStatus !== PinStatus.VERIFIED) {
            await this.showPinDialog(card, pinType);
        }
    }

}
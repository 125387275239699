import "@yoshteq/druide-webcomponents";
import { DruideModal } from "@yoshteq/druide-webcomponents";
import { css, html, LitElement, TemplateResult } from "lit";
import { customElement, query } from "lit/decorators.js";
import "./LoaderSpinner";

export type UploadInfoStatus = "pending" | "success" | "error";
export type UploadInfo = {
    info: File;
    status: UploadInfoStatus;
};
export type UploadInfoPending = UploadInfo & {
    uploadPercentage: number;
};
export type UploadInfoSuccess = UploadInfo & {
    data: ArrayBuffer;
};
export type UploadInfoError = UploadInfo & {
};
@customElement("druide-drag-drop-upload")
export class DruideDragDropUpload extends LitElement {
    static styles = css` `;
    private enterCounter = 0;
    @query("#drop-zone")
    dropZone!: DruideModal;

    override connectedCallback(): void {
        super.connectedCallback();
        window.addEventListener("drop", this.onDrop);
        window.addEventListener("dragover", this.preventDefault);
        window.addEventListener("dragenter", this.dragEnter);
        window.addEventListener("dragleave", this.dragLeave);
    }
    override disconnectedCallback(): void {
        super.disconnectedCallback();
        window.removeEventListener("drop", this.onDrop);
        window.removeEventListener("dragover", this.preventDefault);
        window.removeEventListener("dragenter", this.dragEnter);
        window.removeEventListener("dragleave", this.dragLeave);
    }
    private dragEnter = () => {
        this.enterCounter++;
        this.dropZone.show();
    };
    private dragLeave = () => {
        this.enterCounter--;
        if (this.enterCounter <= 0) {
            this.dropZone.hide();
        }
    };

    private preventDefault = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };
    private onDrop = (e: DragEvent) => {

        this.preventDefault(e);
        if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.receiveFile(e.dataTransfer.files[0]);
        }
        this.enterCounter = 0;
        this.dropZone.hide();
        this.requestUpdate();
    };

    private receiveFile(file: File) {
        const fileInfo = file;
        const uploadStartInfo: UploadInfoPending = {
            info: fileInfo,
            status: "pending",
            uploadPercentage: 0
        };
        this.dispatchEvent(new CustomEvent("file-upload", { detail: uploadStartInfo }));
        this.requestUpdate();

        const reader = new FileReader();
        reader.onprogress = (e) => {
            const uploadProgressInfo: UploadInfoPending = {
                info: fileInfo,
                status: "pending",
                uploadPercentage: e.loaded / e.total
            };
            this.dispatchEvent(new CustomEvent("file-upload", { detail: uploadProgressInfo }));
        };

        this.dispatchEvent(new CustomEvent("file-read", { detail: { reader } }));
        reader.onloadend = () => {
            const fileData = reader.result as ArrayBuffer | null;
            let info: UploadInfo;
            if (!!fileData) {
                info = {
                    info: fileInfo,
                    data: fileData,
                    status: "success"
                } as UploadInfoSuccess;
            } else {
                info = {
                    info: fileInfo,
                    status: "error"
                } as UploadInfoError;
            }
            this.dispatchEvent(new CustomEvent("file-upload", { detail: info }));
            this.requestUpdate();
        };
        reader.readAsArrayBuffer(file);
    }

    protected override render(): TemplateResult {
        return html`
            <druide-modal id="drop-zone"><h1 style="color:white"><slot>Datei hochladen</slot></h1></druide-modal>
            `;
    }


    /** 
     * Triggers an classic File Upload  choose Dialog
    */
    public openUploadFileDialog() {
        const input = document.createElement('input');
        input.type = 'file';
        input.addEventListener("change", () => {
            if (input && input.files && input.files.length > 0) {
                this.receiveFile(input.files[0]);
            }
        });
        input.click();

    }
}


declare global {
    interface HTMLElementTagNameMap {
        "druide-drag-drop-upload": DruideDragDropUpload;
    }
}

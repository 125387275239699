import { KimMailInfo, MailInfo } from "@yoshteq/ti365-ts-sdk";
import { LitElement, TemplateResult, css, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { svgAttachment, svgWarning } from "../Components/SvgConst";

@customElement("kim-list-entry")
export class MailListEntry extends LitElement {
    static styles = css`
    :host {
        box-sizing: border-box;
        --background-color: #fff;
    }
    :host([selected]) > .container {
      border-color: var(--background-color-hover);
      background-color: var(--primary-color-active);
    }
    .container {
        box-sizing: border-box;
        color: var(--text-color);
        display: grid;
        padding: 0.5rem;
        grid-template-areas:
            "title attachment"
            "author error"
            "date error";
        grid-template-columns: 1fr min-content;
        grid-gap: 3px;
        transition: ease 200ms;
        cursor: default;
        background-color: var(--background-color);
        border-left: 0.25rem solid var(--background-color);
    }
    .container:hover {
        background-color: var(--background-color-hover) !important;
        cursor: pointer;
    }

    .container > * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .title {
        grid-area: title;
        font-size: 1.2em;
    }

    .type, .size, .date , .author {
        font-size: 0.8em;
        opacity: 0.6;
    }

    .attachment {
      margin: auto;
    }

    .error > svg {
      height: 32px;
      width: 32px;
      fill: red;
    }

    .type { grid-area: type;}
    .attachment { grid-area: attachment;}
    .date { grid-area: date;}
    .author {grid-area: author;}
    .status {grid-area: status;}
    .error {grid-area: error;}
`;

    constructor(mail: KimMailInfo | MailInfo) {
        super();
        this.mail = mail;
    }

    @property({ type: Object, reflect: false })
    public mail: KimMailInfo | MailInfo;

    protected override render(): TemplateResult {
        if (!this.mail) {
            return html``;
        }
        return html`
      <div class="container">
          <div class="title">${this.mail.subject}</div>
          <div class="author" title="${this.mail.from.address}">${this.mail.from.name ?? this.mail.from.address}</div>
          <div class="attachment">${this.mail.hasAttachments ? svgAttachment : ""}</div>
          <div class="date">${new Date(this.mail.sentDate).toLocaleString()}</div>
          <div class="error">${this.mail.hasErrors ? svgWarning : ""}</div>
      </div>`;
    }
}

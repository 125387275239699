import { css, html, LitElement, TemplateResult } from "lit";
import { customElement } from "lit/decorators.js";
import { CssConst } from "../Components/CssConst";

@customElement("kim-deregister-success")
export class KimDeregisterSuccess extends LitElement {
  static styles = css`
    :host {
        box-sizing: border-box;
    }
`;

  constructor() {
    super();
  }
  private async submit() {
    location.hash = "#ti-service-select";
  }

  protected override render(): TemplateResult {
    return html`
      <div slot="content" style=${CssConst.flexCenter} >
            <druide-card slot="content" style="max-width:400px;min-width:300px;" label="KIM-Account" @submit=${this.submit} >
            Ihr KIM Account ist nun deaktiviert.<br>
            Sollten Sie diesen wieder benötigen, haben Sie ab jetzt, 30 Tage diesen wiederherstellen.
            <druide-button slot="footer-right"  submit>Zur TI-Übersicht</druide-button>
          </druide-card>
      </div>
      `;
  }
}

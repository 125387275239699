import { DruideTextField } from "@yoshteq/druide-webcomponents";

import { watch } from "@lit-labs/preact-signals";
import { StatusEvent, Ti365, Ti365Session } from "@yoshteq/ti365-ts-sdk";
import { LitElement, TemplateResult, html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { portalName } from "./BrandingSettings";
import { CssConst } from "./Components/CssConst";
import { svgLogin } from "./Components/SvgConst";
import { Global } from "./Global";
import { TiPortal } from "./TiPortal";


@customElement("login-view")
export class LoginView extends LitElement {

    @query("#username")
    usernameInput!: DruideTextField;
    @query("#password")
    passwordInput!: DruideTextField;
    @query("#sms-pin")
    smsPinInput!: DruideTextField;

    @property()
    statusMessage = "";
    @property()
    username: string = Global.devUser;

    @state()
    state: "login" | "pin" = "login";

    private authenticationService = Ti365.createAuthenticationService();

    protected override render(): TemplateResult {
        return html`
            <header-layout>
                <div slot="header-left"><img src="/branding/logo"/></div>
                <div slot="header-center">${watch(portalName)}</div>
                ${this.state === "login" ? this.renderLoginInput() : this.renderPinInput()}
            </header-layout>
           `;
    }

    protected renderLoginInput() {
        return html`
        <div slot="content" style=${CssConst.flexCenter} @submit=${this.login}>
            <druide-card slot="content" style="max-width:400px;min-width:300px;" label="Login">
                <div style="display:flex; flex-direction:column">
                    <div>
                        Hier können Sie sich in ihrem persönlichen Telematik Account anmelden.
                    </div>
                    <druide-text-field id="username" label="Benutzername:" value="${this.username}" required autofocus></druide-text-field>
                    <druide-text-field id="password" label="Passwort:" value="${Global.devPw}" type="password" required>
                    </druide-text-field>
                </div>
                <div slot="footer-left" style="align-self:center; font-size:0.85em;">${this.statusMessage}</div>
                <druide-button slot="footer-right" title="Login" submit .icon=${svgLogin} ></druide-button>
            </druide-card>
        </div>`;
    }

    public async login() {
        this.statusMessage = "Anmeldung wird geprüft";

        const loginResult = await this.authenticationService.login(this.usernameInput.value, this.passwordInput.value);
        switch (loginResult.status) {
            case "SUCCESS":
                this.onSuccessfulLogin(loginResult.session!);
                break;
            case "CONFIGURATION_INCOMPLETE":
                this.statusMessage = "Ihre Konfiguration wurde noch nicht vollständig abgeschlossen";
                break;
            case "MFA_REQUIRED":
                this.statusMessage = "";
                this.state = "pin";
                break;
            case "LOGIN_FAILED":
                this.statusMessage = "Die Anmeldedaten sind ungültig";
                break;
            default:
                throw new Error("Unknown login result status");
        }
    }

    protected renderPinInput() {
        return html`
        <div slot="content" style=${CssConst.flexCenter} @submit=${this.confirmDevice}>
            <druide-card slot="content" style="max-width:400px;min-width:300px;" label="Login an neuem Gerät">
                <div style="display:flex; flex-direction:column">
                    <div>
                        Sie melden sich an einem neuen Gerät an. <br>
                        Für Ihre Sicherheit müssen Sie dies durch eine SMS-PIN bestätigen.<br>
                        Wir haben Ihnen diese PIN auf die hinterlegte Handynummer gesendet.
                    </div>
                    <druide-text-field id="sms-pin" label="SMS-Pin:" value="" type="tel" required autofocus></druide-text-field>
                </div>
                <div slot="footer-left" style="align-self:center; font-size:0.85em;">${this.statusMessage}</div>
                <druide-button slot="footer-right" title="Weiter" submit ></druide-button>
            </druide-card>
        </div>`;
    }

    public async confirmDevice() {
        this.statusMessage = "PIN wird überprüft";

        const loginResult = await this.authenticationService.confirmMfa(this.smsPinInput.value);
        switch (loginResult.status) {
            case "SUCCESS":
                this.onSuccessfulLogin(loginResult.session!);
                break;
            case "CONFIGURATION_INCOMPLETE":
                this.statusMessage = "Ihre Konfiguration wurde noch nicht vollständig abgeschlossen";
                break;
            case "MFA_INVALID":
                this.statusMessage = "Die SMS-PIN ist nicht korrekt.";
                break;
            case "MFA_TRIES_EXCEEDED":
                this.statusMessage = "Maximale Anzahl von Pin Versuchen überschritten. Bitte melden Sie sich neu an.";
                break;
            default:
                throw new Error("Unknown login result status");
        }
    }

    private onSuccessfulLogin(session: Ti365Session) {
        TiPortal.tiSession = session;
        StatusEvent.login();
    }
}

export function byteToHuman(bytes: number) {
    const suffix = ["B", "KB", "MB"];
    let i = 0;
    for (; i < 3; i++) {
        if (bytes < Math.pow(1024,i + 1)) {
            break;
        }
    }
    const sizeStr = (bytes / Math.pow( 1024,i )).toFixed(2);
    return sizeStr + " " + suffix[i];
}

export function downloadBlob(blob: Blob) {
    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.target = "_blank"
    a.href = blobUrl;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(blobUrl);
}
import { EpaFolder } from "@yoshteq/ti365-ts-sdk";
import { html, LitElement, TemplateResult } from "lit";
import { customElement, property } from "lit/decorators.js";
import "./EpaSimpleListEntry";


@customElement("epa-folder-entry")
export class EpaFolderEntry extends LitElement {

    @property({ type: Object })
    public epaFolder?: EpaFolder;

    constructor(epaFolder?: EpaFolder) {
        super();
        if (epaFolder) {
            this.epaFolder = epaFolder;
        }
    }

    protected override render(): TemplateResult {
        if (!this.epaFolder) {
            return html``;
        }
        let fileCount = this.epaFolder.fileCount + " Dateien";
        if (this.epaFolder.fileCount === 1) {
            fileCount = this.epaFolder.fileCount + " Datei";
        } else if (this.epaFolder.fileCount === undefined) {
            fileCount = "";
        }

        return html`
            <epa-simple-list-entry 
            title=${this.epaFolder.name} 
            detail-left=${fileCount} 
            detail-right=${this.epaFolder.lastUpdated.toLocaleDateString()}>`;
    }
}

import { DruideModal } from "@yoshteq/druide-webcomponents";
import { LitElement, TemplateResult, css, html } from "lit";
import { customElement, property, query } from "lit/decorators.js";

@customElement("error-dialog")
export default class ErrorDialog extends LitElement {
    static styles = css`
        .dialog {
            background-color: white;
            padding: 20px;
            border-radius: 5px;
            max-width: 600px;
            width: 90%;
        }
    `;

    @property()
    title: string = "";

    @property()
    content: string | TemplateResult = "";

    @query("druide-modal")
    modal!: DruideModal;

    protected render(): TemplateResult {
        return html`
            <druide-modal close-explicit>
                <div class="dialog">
                    <h3>${this.title}</h3>
                    ${this.content}
                    <div class="dialog-buttons">
                        <druide-button @click=${() => this.onCloseClicked()}>Schließen</druide-button>
                    </div>
                </div>
            </druide-modal>
        `;
    }

    public show() {
        this.modal.show();
    }

    private onCloseClicked() {
        this.modal.hide();
    }
}
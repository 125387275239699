import { downloadBlob } from "@/Functions";
import { TiPortal } from "@/TiPortal";
import { DruideModal, DruideToast, WarningLevel } from "@yoshteq/druide-webcomponents";
import {
    CardPinNotVerifiedDetails,
    KiMClient,
    KimClientError,
    KimEncryptedMailInfo,
    KimMail,
    KimMailInfo,
    MailInfo,
    MailObject,
    MessageReceiveListener,
    PinType,
    Ti365GatewayNetworkError,
    Ti365MailFolderService,
} from "@yoshteq/ti365-ts-sdk";
import { LitElement, TemplateResult, css, html } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import "../Components/DruideDialog";
import "../Components/MasterDetailLayout";
import MasterDetailLayout from "../Components/MasterDetailLayout";
import "../Components/PrimaryNavigationItem";
import "../Components/StackedNavigation";
import StackedNavigation from "../Components/StackedNavigation";
import { StatusEventController } from "../Components/StatusEventController";
import { svgBackArrow, svgDelete, svgError, svgInbox, svgMailNew, svgMailSync, svgSentItems, svgSettings } from "../Components/SvgConst";
import { EnterPinDialog } from "../EnterPinDialog";
import "./KimListEntry";
import "./KimMailProcessingListEntry";
import { KimFetchMailInfo } from "./KimMailProcessingListEntry";
import { KimMailView } from "./KimMailView";
import { KimNewMail } from "./KimNewMail";
import { KimReactivate } from "./KimReactivate";
import { KimSettings } from "./KimSettings";

@customElement("kim-overview")
export class KimOverview extends LitElement {
    static styles = css`
        .error-heading {
            display: flex;
            align-items: center;
        }

        .error-icon {
            --icon-color: red;
            margin-right: 10px;
            height: 36px;
            width: 36px;
        }

        .error-action {
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;
        }

        .content-container {
            position: relative;
        }

        #toast-container {
            position: fixed;
            display: flex;
            flex-direction: column;
            align-items: center;
            bottom: 0;
            left: 0;
            right: 0;
        }
        #toast-container > * {
            margin: 5px;

        }
    `;

    status = new StatusEventController(this);
    content: HTMLElement | undefined;
    @query("master-detail-layout")
    layout!: MasterDetailLayout;
    @query("#toast-container")
    toastContainer!: HTMLDivElement;
    @query("#hbaPinModal")
    hbaPinModal!: DruideModal;
    @query("stacked-navigation")
    stackedNavigation!: StackedNavigation;
    @query("[slot=secondaryNavigation]")
    secondaryNavigationList!: HTMLElement;

    @state()
    private selectedFolder = "inbox";

    @state()
    private mailInfo: KimMailInfo[] | MailInfo[] = [];

    @state()
    private fetchMailInfo: KimFetchMailInfo[] = [];

    private kimClient?: KiMClient;

    private mailFolderService?: Ti365MailFolderService;

    @state()
    private active: boolean = true;

    @state()
    private shownMessageId?: string;

    @state()
    private loadingError?: string;

    override connectedCallback(): void {
        super.connectedCallback();
        window.addEventListener("popstate", this.popstate);

        this.init();

        this.addEventListener("sent-mail", async (e) => {
            if (e instanceof CustomEvent) {
                const mail = e.detail.mail;
                await this.mailFolderService!.storeMail("sent", mail);
            }
        });
        this.addEventListener("delete-kim-mail", async (e) => {
            if (e instanceof CustomEvent) {
                const mail = e.detail.mail;
                await this.deleteMail(mail);
                location.hash = "#kim";
                e.detail.onSuccess();
            }
        });
        this.addEventListener("download-attachment", async (e) => {
            if (e instanceof CustomEvent) {
                const byteData = await this.getAttachment(e.detail.storageId, e.detail.attachment.storageId);
                downloadBlob(new Blob([byteData], { type: e.detail.attachment.mimeType }));
            }
        });
        this.addEventListener("postToast", (e) => {
            if (e instanceof CustomEvent) {
                const toast = new DruideToast();
                toast.warningLevel = e.detail.warningLevel ?? WarningLevel.INFO;
                toast.text = e.detail.text ?? "";
                this.toastContainer.appendChild(toast);
                toast.show();
                setTimeout(() => {
                    this.toastContainer.removeChild(toast);
                }, (toast.showSeconds + 1) * 1000);
            }
        });
    }

    override disconnectedCallback(): void {
        super.disconnectedCallback();
        window.removeEventListener("popstate", this.popstate);
    }

    private async init() {
        try {
            await this.initKimClient();
            this.mailFolderService = await TiPortal.tiSession.mailFolderService;
            this.mailInfo = (await this.kimClient!.listMailInfos()).sort((a, b) => new Date(b.sentDate).getTime() - new Date(a.sentDate).getTime());
            if (this.active) {
                await this.syncMails();
            }
            this.navigateToHash();
        } catch (e) {
            if (e instanceof KimClientError) {
                if (e.code === "ACCOUNT_MANAGER_ERROR") {
                    this.loadingError = "Der Account konnte nicht geladen worden. Bitte versuchen Sie es erneut!";
                } else if (e.code === "KIM_ACCOUNT_NOT_CONFIGURED" || e.code == "KIM_CONFIGURATION_ERROR") {
                    location.hash = "#kim-setup";
                }
            } else if (e instanceof Ti365GatewayNetworkError) {
                this.loadingError = "Netzwerkfehler, bitte überprüfen Sie ihre Internet-Verbindung!";
            } else {
                this.loadingError = "Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es erneut!";
            }
        }
    }

    private async initKimClient() {
        this.kimClient = await TiPortal.kimClient;
        this.kimClient.listener = {
            onActiveState: (active) => this.onActiveState(active),
        };

        this.active = this.kimClient.isActive;
    }

    private async onRequestCardPin(cardHandle: string, pinType: PinType, onSuccess: () => void) {
        const card = await TiPortal.tiSession.cardService.findCardWithCardHandle(cardHandle);
        const epd = new EnterPinDialog();
        epd.card = card;
        epd.pinType = pinType;
        this.hbaPinModal.appendChild(epd);

        epd.addEventListener("pin-success", () => {
            this.hbaPinModal.hide();
            this.hbaPinModal.removeChild(epd);
            onSuccess();
        });

        this.hbaPinModal.show();
    }

    private async syncMails() {
        try {
            await this.kimClient?.fetchNewMails(this.messageReceiveListener);
        } catch (error: any) {
            if (error instanceof KimClientError) {
                if (error.code === "CARD_PIN_NOT_VERIFIED" && error.detail instanceof CardPinNotVerifiedDetails) {
                    this.onRequestCardPin(error.detail.cardHandle, error.detail.requiredPinType, () => this.syncMails());
                } else {
                    // TODO handle error
                }
            }
        }
    }

    private messageReceiveListener: MessageReceiveListener = {
        onFetchingNewMail: (id) => setTimeout(() => this.onFetchingNewMail(id), 0),
        onFetchingNewMailError: (id, message) => setTimeout(() => this.onFetchingNewMailError(id, message), 0),
        onDecryptingNewMail: (id, mail) => setTimeout(() => this.onDecryptingNewMail(id, mail), 0),
        onVerifyNewMail: (id) => setTimeout(() => this.onVerifyNewMail(id), 0),
        onDownloadingMailContent: (id) => setTimeout(() => this.onDownloadingMailContent(id), 0),
        onNewMailProcessed: (info) => setTimeout(() => this.onNewMailProcessed(info), 0),
    };

    private onActiveState(active: boolean): void {
        this.active = active;
    }

    private onFetchingNewMail(id: string) {
        const index = this.fetchMailInfo.findIndex((i) => i.messageId === id);
        if (index === -1) {
            const fetchInfo: KimFetchMailInfo = { messageId: id, title: "...", subtitle: "Daten werden geladen" };
            this.fetchMailInfo = [...this.fetchMailInfo, fetchInfo];
        }
    }

    private onFetchingNewMailError(id: string, message: string): any {
        const index = this.fetchMailInfo.findIndex((i) => i.messageId === id);
        const mailInfo = this.fetchMailInfo.splice(index, 1)[0];
        mailInfo.subtitle = message;
        mailInfo.title = "[Fehler]";
        mailInfo.hasErrors = true;
        this.fetchMailInfo = [mailInfo, ...this.fetchMailInfo];

        this.requestUpdate();
    }

    private onDecryptingNewMail(id: string, mail: KimEncryptedMailInfo) {
        const index = this.fetchMailInfo.findIndex((i) => i.messageId === id);
        const mailInfo = this.fetchMailInfo.splice(index, 1)[0];
        mailInfo.subtitle = "Nachricht wird entschlüsselt";
        mailInfo.date = mail.sentDate;
        mailInfo.kimVersion = mail.kimVersion;
        this.fetchMailInfo = [mailInfo, ...this.fetchMailInfo];

        this.requestUpdate();
    }

    private onVerifyNewMail(id: string) {
        this.changeFetchMailInfoSubtitle(id, "Nachricht wird verifiziert");
    }

    private onDownloadingMailContent(id: string) {
        this.changeFetchMailInfoSubtitle(id, "Inhalt der Mail wird geladen");
    }

    private changeFetchMailInfoSubtitle(id: string, subtitle: string) {
        const index = this.fetchMailInfo.findIndex((i) => i.messageId === id);
        const mailInfo = this.fetchMailInfo.splice(index, 1)[0];
        mailInfo.subtitle = subtitle;
        this.fetchMailInfo = [mailInfo, ...this.fetchMailInfo];

        this.requestUpdate();
    }

    private onNewMailProcessed(mailInfo: KimMail) {
        const index = this.fetchMailInfo.findIndex((i) => i.messageId === mailInfo.messageId);
        this.fetchMailInfo.splice(index, 1)[0];
        this.fetchMailInfo = [...this.fetchMailInfo];

        this.mailInfo = [...(this.mailInfo as KimMailInfo[]), mailInfo].sort((a, b) => new Date(b.sentDate).getTime() - new Date(a.sentDate).getTime());

        this.requestUpdate();
    }

    protected override render(): TemplateResult {
        if (this.kimClient) {
            return html`
                <header-layout>
                    ${this.renderHeader()}
                    <master-detail-layout slot="content">
                        <stacked-navigation slot="navigation">
                            <druide-list-menu slot="primaryNavigation" style="margin:0px;">
                                <primary-navigation-item .icon=${svgInbox} text="Posteingang" @click=${this.showInboxMailsNavigation}></primary-navigation-item>
                                <primary-navigation-item .icon=${svgSentItems} text="Gesendete Elemente"  @click=${this.showSentMailsNavigation}></primary-navigation-item>
                                <primary-navigation-item .icon=${svgDelete} text="Papierkorb" @click=${this.showDeletedMailsNavigation}></primary-navigation-item>
                            </druide-list-menu>
                            <druide-list-menu slot="secondaryNavigation" style="margin:0px;">
                                ${this.selectedFolder === "inbox" ? this.fetchMailInfo.map((mail) => this.createProcessingMailListEntry(mail)) : undefined}
                                ${this.mailInfo.map((mail) => this.createMailListEntry(mail))}
                            </druide-list-menu>
                        </stacked-navigation>
                        <div class="content-container" slot="content">
                            ${this.content}
                            <div id="toast-container"></div>
                        </div>
                    </master-detail-layout>
                </header-layout>
                <druide-modal close-explicit id="hbaPinModal">
                </druide-modal>
                `;
        } else {
            return html`
                <druide-dialog shownOnStart>
                    ${this.renderLoadingDialog()}
                </druide-dialog>
            `;
        }
    }

    private renderHeader(): TemplateResult {
        if (this.active) {
            return html`  
            <div slot="header-left">
                <druide-icon-button id="app-button" class="back-header" @click=${() => history.back()}>${svgBackArrow}</druide-icon-button>
                <druide-icon-button @click=${this.syncMails}>${svgMailSync}</druide-icon-button>
            </div>
            <div slot="header-center">KIM Postfach</div>
            <div slot="header-right">
                <druide-icon-button @click=${this.sendNewMail}>${svgMailNew}</druide-icon-button>
                <druide-icon-button @click=${this.showSettings}>${svgSettings}</druide-icon-button>
            </div>`;
        } else {
            return html`  
            <div slot="header-left"><druide-icon-button id="app-button" class="back-header" @click=${() => history.back()}>${svgBackArrow}</druide-icon-button>            </div>
            <div slot="header-center">KIM Postfach ${this.active ? "" : "(deaktiviert)"}</div>
            <div slot="header-right"><druide-icon-button @click=${this.showReactivate}>${svgSettings}</druide-icon-button> </div>`;
        }
    }

    private renderLoadingDialog() {
        if (this.loadingError) {
            return html`
                <div class="error-heading">
                    <druide-icon .svg=${svgError} class="error-icon"></druide-icon>
                    <h3>Fehler beim Laden</h3>
                </div>
                ${this.loadingError}
                <div class="error-action">
                    <druide-button @click=${() => history.back()}>Zur Übersicht</druide-button>
                </div>
            `;
        } else {
            return html`
                <loader-message>Account wird geladen ...</loader-message>
            `;
        }
    }


    private popstate = async (event: PopStateEvent) => {
        if (location.hash === "#kim/re-activate" && this.kimClient?.isActive) {
            event.preventDefault();
            history.back();
            return;
        }

        await this.navigateToHash();
    };

    private async navigateToHash() {
        if (location.hash === "#kim") {
            this.content = undefined;
            this.layout.showNavigation();
            this.requestUpdate();
        } else if (location.hash === "#kim/new") {
            this.sendNewMail();
        } else if (location.hash === "#kim/settings") {
            this.showSettings();
        } else if (location.hash === "#kim/re-activate") {
            this.showReactivate();
        } else if (location.hash.startsWith("#kim/reply/")) {
            const match = /#kim\/reply\/(.*)$/gm.exec(location.hash);
            if (match?.[1]) {
                const id = match[1];
                await this.replyMail(id);
            }
        } else if (location.hash.startsWith("#kim/view/")) {
            const match = /#kim\/view\/(.*)$/gm.exec(location.hash);
            if (match?.[1]) {
                const id = match[1];
                await this.viewMail(id);
            }
        } else if (location.hash.startsWith("#kim/send/")) {
            const match = /#kim\/send\/(.*)$/gm.exec(location.hash);
            if (match?.[1]) {
                const mailAddress = match[1];
                await this.sendNewMailTo(mailAddress);
            }
        }
    }

    private sendNewMail() {
        this.openView(new KimNewMail(), "#kim/new");
    }

    private async sendNewMailTo(mailAddress: string) {
        const receiver = await this.kimClient?.searchReceiver(mailAddress, 1);
        if (!receiver && receiver!.length > 1) {
            throw new Error("Got no receiver or more than one receiver for mail address");
        }
        const view = new KimNewMail();
        view.receivers = [{ name: receiver![0].displayName, mail: mailAddress }];
        this.openView(view, "");
    }

    private showSettings() {
        this.openView(new KimSettings(), "#kim/settings");
    }

    private showReactivate() {
        this.openView(new KimReactivate(), "#kim/re-activate");
    }

    private openView(element: HTMLElement, url: string, pushToHistoryState: boolean = true) {
        // only push new state when url does change and not explicitly disabled
        if (location.hash !== url && pushToHistoryState) {
            history.pushState({}, url, url);
        }
        this.content = element;
        this.layout.showContent();
        this.requestUpdate();
    }

    private createMailListEntry(mail: KimMailInfo): TemplateResult {
        return html`<kim-list-entry ?selected=${mail.storageId === this.shownMessageId} @click=${() => this.viewMail(mail.storageId)} .mail=${mail}></kim-list-entry>`;
    }

    private createProcessingMailListEntry(mail: KimFetchMailInfo): TemplateResult {
        return html`<kim-processing-mail-list-entry .mailFetchInfo=${mail}></kim-processing-mail-list-entry>`;
    }

    private async replyMail(mailStorageId: string) {
        const mailContent = await this.kimClient!.getMailContent(mailStorageId);
        this.openView(new KimNewMail(mailContent), "#kim/reply/" + mailStorageId);
    }

    private async viewMail(mailStorageId: string, dummy = false) {
        if (dummy) {
            return;
        }
        this.shownMessageId = mailStorageId;
        const mailContent = await this.loadMail(mailStorageId);
        const view = new KimMailView(mailContent);
        view.addEventListener("mail-reply", () => {
            this.replyMail(mailStorageId);
        });
        const pushMailToHistoryStack = this.layout.isMasterAndDetailVisible() === false
        this.openView(view, "#kim/view/" + mailStorageId, pushMailToHistoryStack);
    }

    private async loadMail(mailStorageId: string) {
        if (this.selectedFolder === "inbox") {
            return await this.kimClient!.getMailContent(mailStorageId);
        } else {
            return await this.mailFolderService!.loadMail(this.selectedFolder, mailStorageId);
        }
    }

    private async deleteMail(mail: KimMail | MailObject) {
        if (this.selectedFolder === "inbox" && mail instanceof KimMail) {
            await Promise.all(
                mail.attachmentInfos.map(async (info) => {
                    const attachment = await this.kimClient!.getMailAttachment(mail.storageId, info.storageId);
                    await this.mailFolderService!.storeMailAttachment("deleted", mail.storageId, info, attachment);
                }),
            );
            await this.kimClient?.deleteStoredMessage(mail.storageId);
            await this.mailFolderService?.storeMail("deleted", mail.rawMail);
        } else {
            await this.mailFolderService?.deleteMail(this.selectedFolder, mail.storageId);
        }
        this.mailInfo = this.mailInfo.filter((mailInfo) => mailInfo.storageId != mail.storageId) as MailInfo[] | KimMailInfo[];
    }

    private async getAttachment(storageId: string, attachmentId: string) {
        if (this.selectedFolder === "inbox") {
            return await this.kimClient!.getMailAttachment(storageId, attachmentId);
        } else {
            return await this.mailFolderService!.getMailAttachment(this.selectedFolder, storageId, attachmentId);
        }
    }

    private async showInboxMailsNavigation() {
        this.selectedFolder = "inbox";
        this.mailInfo = (await this.kimClient!.listMailInfos()).sort((a, b) => new Date(b.sentDate).getTime() - new Date(a.sentDate).getTime());
        this.stackedNavigation.showSecondaryNavigation();
    }

    private async showSentMailsNavigation() {
        this.selectedFolder = "sent";
        this.mailInfo = (await this.mailFolderService!.listMailInfos("sent")).sort((a, b) => new Date(b.sentDate).getTime() - new Date(a.sentDate).getTime());
        this.stackedNavigation.showSecondaryNavigation();
    }

    private async showDeletedMailsNavigation() {
        this.selectedFolder = "deleted";
        this.mailInfo = (await this.mailFolderService!.listMailInfos("deleted")).sort((a, b) => new Date(b.sentDate).getTime() - new Date(a.sentDate).getTime());
        this.stackedNavigation.showSecondaryNavigation();
    }
}

import { css, html, LitElement, TemplateResult } from "lit";
import { customElement, property } from "lit/decorators.js";

@customElement("loader-spinner")
export class LoaderSpinner extends LitElement {
    static styles = css`
    :host {
        --i-spinner-color: var(--spinner-color, var(--primary-color));
        display: inline-block;
        width: 80px;
        height: 80px;
        overflow:hidden;
    }
    :host:after {
        content: " ";
        display: block;
        width: calc(100% - 14px);
        height: calc(100% - 14px);
        margin: 0px;
        border-radius: 50%;
        border: 6px solid var(--i-spinner-color);
        border-color: var(--i-spinner-color) transparent var(--i-spinner-color) transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }
    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }   `;

    @property({ type: String })
    set color(s: string) {
        this.style.setProperty("--spinner-color", s);
    }

    protected override render(): TemplateResult {
        return html``;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "loader-spinner": LoaderSpinner;
    }
}

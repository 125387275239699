import { TiPortal } from "@/TiPortal";
import { DruideCheckbox, DruideDateField, DruideTextArea, DruideTextField, WarningLevel } from "@yoshteq/druide-webcomponents";
import { KimAccountState, KimLog, OutOfOfficeNotification } from "@yoshteq/ti365-ts-sdk";
import { LitElement, TemplateResult, css, html } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { StatusEventController } from "../Components/StatusEventController";

@customElement("kim-settings")
export class KimSettings extends LitElement {
  static styles = css`
    :host {
        box-sizing: border-box;
    }
    .main-layout{
      display:flex;
      flex-direction:column;
      
    }
    .main-layout > druide-date-field{
      padding-top:0;
    }
`;
  private status: StatusEventController = new StatusEventController(this);

  @state()
  private passwordChangeMessage = "";

  @state()
  private kimAccountDetails?: Partial<KimAccountState> = {};
  @state()
  private outOfOffice: Partial<OutOfOfficeNotification> = {};
  @state()
  private pending: string | undefined = undefined;
  @state()
  private pendingLoadAccount: string | undefined = undefined;
  @state()
  private pendingSetVersion: string | undefined = undefined;
  @query("#active")
  active!: DruideCheckbox;
  @query("#start")
  start!: DruideDateField;
  @query("#end")
  end!: DruideDateField;
  @query("#message")
  message!: DruideTextArea;
  @query("#kimAddress")
  kimAddress!: DruideTextField;

  @query("#passwordOld")
  passwordOld!: DruideTextField;
  @query("#passwordNew")
  passwordNew!: DruideTextField;
  @query("#passwordNewRepeat")
  passwordNewRepeat!: DruideTextField;

  constructor() {
    super();
    this.refreshAccountInformation(false);
    this.loadOutOfOffice();
  }

  private async loadOutOfOffice() {
    try {
      this.pending = "Lade aktuellen Status der automatischen Antwort";
      const kimClient = await TiPortal.kimClient;
      const outOfOfficeNotice = await kimClient.getOutOfOfficeNotice();
      this.outOfOffice = outOfOfficeNotice ?? {};
      this.pending = undefined;
      this.requestUpdate();
    } catch (stacktrace) {
      KimLog.error(stacktrace);
      this.pending = "Fehler beim laden der automatischen Antwort.";
      this.requestUpdate();
    }
  }

  private fmtDate(date: Date | undefined): string {
    if (date == undefined) {
      return "";
    }
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    const monthInt = date.getMonth() + 1;
    const month = monthInt < 10 ? "0" + monthInt : monthInt;
    return date.getFullYear() + "-" + month + "-" + day;
  }

  protected changeActive() {
    this.outOfOffice.active = this.active.value;
    this.requestUpdate();
  }
  private ensureRequired(elm: { value: string, error: string | undefined; }) {
    if (elm.value === "") {
      elm.error = "Bitte einen Wert angeben";
      return false;
    } else {
      elm.error = undefined;
    }
    return true;
  }

  protected async storeOutOfOffice() {
    this.outOfOffice.active = this.active.value;
    if (this.outOfOffice.active) {
      if (!(this.ensureRequired(this.start) && this.ensureRequired(this.end) && this.ensureRequired(this.message))) {
        return;
      }
    }
    this.outOfOffice.startDate = new Date(this.start.value);
    this.outOfOffice.endDate = new Date(this.end.value);
    this.outOfOffice.message = this.message.value;

    try {
      this.pending = "Speichere automatische Antwort.";
      this.requestUpdate();
      const kimClient = await TiPortal.kimClient;
      await kimClient.setOutOfOfficeNotice(this.outOfOffice as OutOfOfficeNotification);
      this.dispatchEvent(new CustomEvent("postToast", { detail: { warningLevel: WarningLevel.CHECK, text: "Automatische Antwort erfolgreich gespeichert" }, composed: true }));
      this.requestUpdate();
    } catch (e) {
      KimLog.error(e);
      this.dispatchEvent(new CustomEvent("postToast", { detail: { warningLevel: WarningLevel.ERROR, text: "Fehler beim Speichern der automatische Antwort" }, composed: true }));
      this.requestUpdate();
    } finally {
      this.pending = undefined;
    }
  }

  private async deregisterAccount() {
    const kimClient = await TiPortal.kimClient;
    if (this.kimAddress.value.toLocaleLowerCase() !== kimClient.getMailAddress().toLocaleLowerCase()) {
      this.kimAddress.error = "Bitte geben Sie die ihre KIM Adresse ein.";
      return;
    } else {
      this.kimAddress.error = undefined;
    }

    try {
      await kimClient.deregisterAccount();
    } catch (e) {
      this.kimAddress.error = "Der Account konnte nicht deregistriert werden. Bitte versuchen Sie es erneut.";
    }
    location.hash = "#kim-deregister";
  }

  private async refreshAccountInformation(reload = true) {
    const kimClient = await TiPortal.kimClient;
    if (reload) {
      this.pendingLoadAccount = "Daten werden aktualisiert";
      await kimClient.resyncAccountState();
      this.pendingLoadAccount = undefined;
    }
    this.kimAccountDetails = kimClient.accountDetails;
  }

  protected override render(): TemplateResult {
    return html`
      
      <druide-card label="Ihr KIM Account" @submit=${this.refreshAccountInformation} >
        Status: ${this.kimAccountDetails?.regStat}<br>
        Version: ${this.kimAccountDetails?.kimVersion}<br>
        <loader-message style="${this.pendingLoadAccount ? "" : "display:  none"}">${this.pendingLoadAccount}</loader-message>
        <druide-button slot="footer-right" submit>Daten aktualisieren</druide-button>
      </druide-card>

      ${this.renderKimVersionSetting()}

      ${this.pending ? this.renderPending() : this.renderOutOfOfficeInput()}

      <druide-card label="Account Passwort ändern" @submit=${this.changePassword} >
        Mit dieser Funktion können Sie das Passwort des KIM Postfaches ändern.<br>
        Bitte beachten Sie, dass es sich Hierbei <b>nicht</b> um Ihr TI Login Passwort handelt.<br>

        <druide-text-field id="passwordOld" type="password" label="Altes Passwort:" required style="width:300px"></druide-text-field><br>
        <druide-text-field id="passwordNew" type="password" label="Neues Passwort:" required style="width:300px"></druide-text-field><br>
        <druide-text-field id="passwordNewRepeat" type="password" label="Neues Passwort Wiederholung:" required style="width:300px"></druide-text-field><br>
        <p>${this.passwordChangeMessage}</p>
        <druide-button slot="footer-right"  submit>Password ändern</druide-button>
      </druide-card>

      <druide-card label="Account deaktivieren" @submit=${this.deregisterAccount} >
        Mit der Deaktivierung des Accounts geben Sie dieses KIM Adresse wieder frei.<br>
        Hierzu wird ihr Account Erstmal 30 Tage deaktiviert, in diesem Zeitraum können Sie den Account wiederherstellen.
        Nach den 30 Tagen wird das Postfach dann endgültig gelöscht.<br>
        <br>
        Bitte geben Sie die ihre aktuelle KIM Adresse ein, um diese zu Löschen:<br>
        <druide-text-field id="kimAddress" label="KIM-Adresse" required style="width:300px"></druide-text-field>
        <druide-button slot="footer-right"  submit>Account deaktivieren</druide-button>
      </druide-card>

      <druide-card label="KIM Account Umzug" @submit=${this.createTransferPassword} >
        Mit dieser Funktion können Sie den KIM Account auf eine andere Telematik-ID umziehen.<br>
        <h2 id="transferPassword"></h2>
        <druide-button slot="footer-right"  submit>Umzugspasswort erzeugen</druide-button>
      </druide-card>
      `;
  }

  private renderKimVersionSetting() {
    if (this.kimAccountDetails?.kimVersion == "1.5") {
      return html`
        <druide-card label="Empfang großer Nachrichten" @submit=${this.toggleKimVersion}>
          Damit Sie KIM-Nachrichten mit mehr als 15MiB Datengröße empfangen können, müssen Sie dies im Folgenden bestätigen.
          Dies kann zu einer höheren Netzauslastung beim Empfangen von großen Nachrichten führen.
          <loader-message style="${this.pendingSetVersion ? "" : "display:  none"}">${this.pendingSetVersion}</loader-message>
          <druide-button slot="footer-right" submit>Bestätigen</druide-button>
        </druide-card>
      `;
    } else {
      return html`
        <druide-card label="Empfang großer Nachrichten" @submit=${this.toggleKimVersion}>
          Damit Sie nicht länger KIM-Nachrichten mit mehr als 15MiB Datengröße empfangen, müssen Sie diese Einstellung widerrufen.
          <loader-message style="${this.pendingSetVersion ? "" : "display:  none"}">${this.pendingSetVersion}</loader-message>
          <druide-button slot="footer-right" submit>Widerrufen</druide-button>
        </druide-card>
      `;
    }
  }

  private async toggleKimVersion() {
    this.pendingSetVersion = "Einstellung wird übernommen";
    const kimClient = await TiPortal.kimClient;
    try {
      await kimClient.setAcceptKasMessages(this.kimAccountDetails?.kimVersion == "1.5");
      this.pendingSetVersion = undefined;
      this.kimAccountDetails = kimClient.accountDetails;
    } catch (e) {
      this.pendingSetVersion = "Die Einstellung konnte nicht übernommen werden. Bitte versuchen Sie es erneut!";
    }
  }

  private async createTransferPassword() {
    const kimClient = await TiPortal.kimClient;
    try {
      const transferPassword = await kimClient.createTransferPassword();
      if (transferPassword !== undefined) {
        this.shadowRoot!.querySelector("#transferPassword")!.textContent = "Ihr Umzugspasswort: " + transferPassword;
      } else {
        this.shadowRoot!.querySelector("#transferPassword")!.textContent = "Es konnte kein Umzugspasswort erzeugt werden. Bitte versuchen Sie es erneut!";
      }
    } catch (e) {
      this.shadowRoot!.querySelector("#transferPassword")!.textContent = "Es konnte kein Umzugspasswort erzeugt werden. Bitte versuchen Sie es erneut!";
    }
  }

  private async changePassword() {
    const kimClient = await TiPortal.kimClient;

    if (this.passwordNew.value !== this.passwordNewRepeat.value) {
      this.passwordNewRepeat.error = "Die Wiederholung des neuen Passwords ist nicht korrekt.";
      return;
    } else {
      this.passwordNewRepeat.error = undefined;
    }

    if (!kimClient.passwordMatchesCurrent(this.passwordOld.value)) {
      this.passwordOld.error = "Das Passwort ist nicht korrekt";
    } else {
      this.passwordOld.error = undefined;
    }

    if (this.passwordNew.value.length < 8) {
      this.passwordNew.error = "Das Passwort ist kürzer wie 8 Zeichen.";
    } else {
      this.passwordNew.error = undefined;
    }

    if (this.passwordOld.error || this.passwordNew.error) {
      return;
    }

    try {
      await kimClient.updatePassword(this.passwordNew.value);
      this.dispatchEvent(new CustomEvent("postToast", { detail: { warningLevel: WarningLevel.CHECK, text: "Passwort wurde erfolgreich geändert" }, composed: true }));
    } catch (e) {
      this.dispatchEvent(new CustomEvent("postToast", { detail: { warningLevel: WarningLevel.ERROR, text: "Es ist ein Fehler beim Ändern des Passworts aufgetreten" }, composed: true }));
    }
    this.requestUpdate();
  }

  protected renderPending(): TemplateResult {
    return html`<druide-card label="Automatische Antwort">
      <loader-message>${this.pending}</loader-message>
    </druide-card>`;
  }

  protected renderOutOfOfficeInput(): TemplateResult {
    return html`
      <druide-card label="Automatische Antwort" @submit=${this.storeOutOfOffice}>
        <div class="main-layout" >
          <druide-checkbox id="active" label="Automatische Antwort aktiv" .value=${this.outOfOffice.active || false} @change=${this.changeActive}></druide-checkbox>
          <div>
            <druide-date-field id="start" label="Von" required .disabled=${!this.outOfOffice.active} .value=${this.fmtDate(this.outOfOffice.startDate)}></druide-date-field>
            <druide-date-field id="end" label="Bis" required .disabled=${!this.outOfOffice.active}  .value=${this.fmtDate(this.outOfOffice.endDate)}></druide-date-field>
          </div>
          <druide-text-area id="message" label="Nachrichttext" required  .disabled=${!this.outOfOffice.active} .value=${this.outOfOffice.message || ""} style="height:10ch"></druide-text-area>
        </div>
        <druide-button slot="footer-right"  title="Speichern"  submit>Speichern</druide-button>
      </druide-card>`;
  }
}

import { LitElement, TemplateResult, css, html } from "lit";
import { customElement, property } from "lit/decorators.js";

@customElement("primary-navigation-item")
export default class PrimaryNavigationItem extends LitElement {
    static styles = css`
        :host {
            width: 100%;
            overflow: hidden;
        }

        .container {
            display: flex;
            padding: 10px;
            gap: 10px;
        }

        .text {
            white-space: nowrap;
        }
    `;

    @property({ type: Boolean })
    active = false;

    @property({ type: Object })
    icon?: TemplateResult<2>;

    @property()
    text: string = "";

    protected render(): unknown {
        return html`
            <div class="container">
                <div>${this.icon}</div>
                <div class="text">${this.text}</div>
            </div>
        `;
    }
}

import { TiPortal } from "@/TiPortal";
import { EpaFileInfo } from "@yoshteq/ti365-ts-sdk";
import { css, html, LitElement, TemplateResult } from "lit";
import { customElement, property } from "lit/decorators.js";
import { CssConst } from "../Components/CssConst";
import { StatusEventController } from "../Components/StatusEventController";
import "./EpaSimpleListEntry";

@customElement("epa-pdf-view")
export class EpaPdfView extends LitElement {
    static styles = css`
    :host{
        --l-header-height: var(--header-height,  50px);
        font-size:0; // This is a hack that remove some spacing added around the iframe.
    }
    div{
        font-size:1rem;
    }
    iframe{
        width: 100%;
        box-sizing: border-box;
        height: calc(100vh - var(--l-header-height));
        margin: 0;
        border: 0;
        padding: 0;
    }
    `;
    status = new StatusEventController(this);
    @property({ type: Object })
    public epaFileInfo?: EpaFileInfo;
    blobUrl?: string;

    constructor(epaFileInfo?: EpaFileInfo) {
        super();
        if (epaFileInfo) {
            this.epaFileInfo = epaFileInfo;
        }
    }

    override connectedCallback(): void {
        super.connectedCallback();
        this.loadEpaData();
    }

    private async loadEpaData() {
        if (this.epaFileInfo) {
            const con = await TiPortal.tiSession.getConnector();
            const blob = await con.getPhrService().getDocumentBlob(this.epaFileInfo);
            this.blobUrl = URL.createObjectURL(blob);
            this.requestUpdate();
        }

    }

    protected override render(): TemplateResult {
        if (this.blobUrl) {
            return html`<iframe src="${this.blobUrl}"></iframe>`;
        } else {
            return html`
            <div .style=${CssConst.flexCenter}>
                <druide-card  style="width:360px;" label="Lade ePA Dokument">
                    <loader-message>${this.status.message}</loader-message>
                </druide-card>
            </div>
        `;
        }

    }
}

import { TiPortal } from "@/TiPortal";
import { DruideModal } from "@yoshteq/druide-webcomponents";
import { EpaFileInfo } from "@yoshteq/ti365-ts-sdk";
import { css, html, LitElement, TemplateResult } from "lit";
import { customElement, property } from "lit/decorators.js";
import { CssConst } from "../Components/CssConst";
import { StatusEventController } from "../Components/StatusEventController";


@customElement("epa-delete-view")
export class EpaDeleteView extends LitElement {
    static styles = css``;
    status = new StatusEventController(this);
    @property({ type: Object })
    public epaFileInfo?: EpaFileInfo;
    state: "open" | "confirm" | "pending" = "open";


    constructor(epaFileInfo?: EpaFileInfo) {
        super();
        if (epaFileInfo) {
            this.epaFileInfo = epaFileInfo;
        }
    }

    override connectedCallback(): void {
        super.connectedCallback();
    }

    protected override render(): TemplateResult {

        if (!this.epaFileInfo) {
            this.dispatchEvent(new CustomEvent("epa-reload", { bubbles: true, composed: true }));
            return html``;
        }

        const person = this.epaFileInfo.author.person;
        if (this.state === "pending") {
            return html`
                    <div .style=${CssConst.flexCenter}>
                        <druide-card style="width:360px;" label="ePA Dokument löschen">
                            <loader-message>${this.status.message}</loader-message>
                        </druide-card>
                    </div>`;
        }

        return html`
            <div .style=${CssConst.flexCenter}>
                <druide-card style="width:360px;" label="ePA Dokument löschen">
                    Sind Sie wirklich sicher, dass dieses Dokument gelöscht werden soll?
                    <dl>
                        <dt>Titel</dt>
                        <dd>${this.epaFileInfo.name}</dd>                
                        <dt>Autor</dt>
                        <dd>${person.titel} ${person.vorname} ${person.nachname}</dd>
                    </dl>
                    <druide-button slot="footer-right" @click=${this.showDeleteDocumentModal}>ePA Dokument löschen</druide-button>
                    <druide-button slot="footer-left" @click=${this.cancelDelete} >Abbrechen</druide-button>
                </druide-card>
            </div>
            <druide-modal id="delete-modal"  >
                <druide-card title="Dokument löschen">
                    Sind Sie wirklich sicher, dass dieses Dokument(${this.epaFileInfo?.name}) gelöscht werden soll?
                    Das Dokument kann NICHT wiederhergestellt werden.
                    <druide-button slot="footer-right" @click=${this.deleteDocument}>ePA Dokument löschen</druide-button>
                    <druide-button slot="footer-left" @click=${this.cancelDelete}>Abbrechen</druide-button>
                </druide-card>
            </druide-modal>        
        `;
    }

    private cancelDelete() {
        this.dispatchEvent(new Event("cancel"));
    }
    private showDeleteDocumentModal() {
        this.shadowRoot!.querySelector<DruideModal>("#delete-modal")!.show();
    }
    private async deleteDocument() {
        if (this.epaFileInfo) {
            this.state = "pending";
            this.requestUpdate();
            const connector = await TiPortal.tiSession.getConnector();
            const phrService = connector.getPhrService();
            await phrService.deleteDocuments(this.epaFileInfo);
            this.dispatchEvent(new CustomEvent("epa-reload", { bubbles: true, composed: true }));
        }
    }
}

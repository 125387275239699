import { DruideTextField } from "@yoshteq/druide-webcomponents";
import { CardType, StatusEvent, VersichertenStammdaten } from "@yoshteq/ti365-ts-sdk";
import { Global } from "Global";
import { LitElement, TemplateResult, html } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { CssConst } from "./Components/CssConst";
import { StatusEventController } from "./Components/StatusEventController";
import { svgBackArrow } from "./Components/SvgConst";
import { TiPortal } from "./TiPortal";
import { portalName, BrandingSettings } from "./BrandingSettings";
import { watch } from "@lit-labs/preact-signals";

@customElement("ekg-info-view")
export class EgkInfoView extends LitElement {
    status = new StatusEventController(this);

    @query("#insurance-number")
    insurantInput!: DruideTextField;

    @state()
    readPending = false;

    cardData?: VersichertenStammdaten;

    protected async readCard() {
        this.readPending = true;
        const egk = await TiPortal.tiSession.cardService.getCard(CardType.EGK, true);
        const smcb = await TiPortal.tiSession.cardService.getCard(CardType.SMC_B);
        if (!egk || !smcb) {
            StatusEvent.log("Es konnte keine Karte gefunden werden.");
            this.readPending = false;
            this.requestUpdate();
            return;
        }

        this.cardData = await TiPortal.tiSession.vsdmService.readVersichertenStammdaten(egk.handle, smcb.handle, true);
        this.readPending = false;
        this.requestUpdate();
    }

    protected override render(): TemplateResult {
        return html`
            <header-layout>
                <div slot="header-left"><druide-icon-button id="app-button" class="back-header" @click=${() => history.back()}>${svgBackArrow}</druide-icon-button></div>
                <div slot="header-center">${watch(portalName)}</div>
                <div slot="content" .style=${CssConst.flexCenter}>
                    <druide-card slot="content" style="max-width:800px;" label="Elektronische Gesundheitskarte">
                        <div style="display:flex; flex-direction:column">
                            ${this.readPending ? html`<loader-spinner style="align-self:center"></loader-spinner>` : html`${this.renderCardInfo()}`}
                            <div>${this.status.message}</div>
                        </div>
                        <druide-button slot="footer-right" title="Karte lesen" @click=${this.readCard} .disabled=${this.readPending}></druide-button>
                    </druide-card>
                </div>
            </header-layout>`;
    }

    private renderCardInfo() {
        return !this.cardData ? "" : html`
        <div>
${this.cardData?.persoenlich.versicherter.person.titel}<br>
<span style="font-size: 1.5em"><strong>${this.cardData?.persoenlich.versicherter.person.vorname} ${this.cardData?.persoenlich.versicherter.person.nachname}</strong></span><br>
${this.cardData?.persoenlich.versicherter.person.namenszusatz}<br>
${this.cardData?.persoenlich.versicherter.person.strassenAdresse?.strasse} ${this.cardData?.persoenlich.versicherter.person.strassenAdresse?.hausnummer}<br>
${this.cardData?.persoenlich.versicherter.person.strassenAdresse?.postleitzahl} ${this.cardData?.persoenlich.versicherter.person.strassenAdresse?.ort}<br>
geb. ${this.cardData?.persoenlich.versicherter.person.geburtsdatum.getDate()}.${this.cardData?.persoenlich.versicherter.person.geburtsdatum.getMonth() + 1}.${this.cardData?.persoenlich.versicherter.person.geburtsdatum.getFullYear()}<br>
---------<br>
${this.cardData?.allgemein.versicherter.versicherungsschutz.kostentraeger.Name} (${this.cardData?.allgemein.versicherter.versicherungsschutz.kostentraeger.kostentraegerkennung} )<br>
KVNR: ${this.cardData?.persoenlich.versicherter.versichertenId}<br><br><br>
        </div>`;
    }

}


export const Global = {
    debugSoap: false,
    debugTls: false,
    debugWebsocket: false,
    debugHttp: true,
    debugKim: true,
    debugTerminalLogin: false,
    deleteMailAfterProcessing: true,
    devUser: import.meta.env.DEV ? "dhufnagel" : "",
    devPw: import.meta.env.DEV ? "Demo!TiTest" : "",
    gatewayUrl: import.meta.env.DEV ? "http://localhost:8080" : location.protocol + "//" + location.host,
    // gatewayUrl: import.meta.env.DEV ? "https://app.ti365.de" : location.protocol + "//" + location.host,
    mioViewerUrl: "https://mio.miya.de",
} as const;


import { CssConst } from "@/Components/CssConst";
import { TiPortal } from "@/TiPortal";
import { SelectOption } from "@yoshteq/druide-webcomponents";
import { AuthorInstitution, AuthorPerson, AuthorRole, CardType, CertRefEnum, ClassificationCodeScheme, ConfidentialityCode, DocumentClass, DocumentFormatCode, DocumentTypeCode, EpaMimeType, EpaMimeTypeName, FileAuthor, HealthcareFacilityType, PracticeSettingCode, RecordIdentifier, StatusEvent, StoreFileInfo } from "@yoshteq/ti365-ts-sdk";
import { LitElement, TemplateResult, css, html } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import "../Components/DruideUploadButton";
import { DruideUploadButton } from "../Components/DruideUploadButton";
import { StatusEventController } from "../Components/StatusEventController";

@customElement("epa-upload-document")
export class EpaUploadDocumentView extends LitElement {
    static styles = css`

    .form{
        display:flex;
        flex-direction:column;
    }
    .hide{
        display:none;
    }

    `;

    static readonly ConfidentialityCodes = [ConfidentialityCode.N, ConfidentialityCode.R, ConfidentialityCode.V];
    static readonly DocumentTypeCodes = [DocumentTypeCode.ABRE, DocumentTypeCode.ADCH, DocumentTypeCode.ANAE, DocumentTypeCode.ANTR,
    DocumentTypeCode.AUFN, DocumentTypeCode.BEFU, DocumentTypeCode.BERI, DocumentTypeCode.BESC, DocumentTypeCode.BILD,
    DocumentTypeCode.BSTR, DocumentTypeCode.EINW, DocumentTypeCode.FALL, DocumentTypeCode.FOTO, DocumentTypeCode.FPRO,
    DocumentTypeCode.FUNK, DocumentTypeCode.GEBU, DocumentTypeCode.IMMU, DocumentTypeCode.INTS, DocumentTypeCode.KOMP,
    DocumentTypeCode.MEDI, DocumentTypeCode.MKRO, DocumentTypeCode.ONKO, DocumentTypeCode.OPDK, DocumentTypeCode.PATD,
    DocumentTypeCode.PATH, DocumentTypeCode.PATI, DocumentTypeCode.PFLG, DocumentTypeCode.Patienteneinverstaendniserklaerung,
    DocumentTypeCode.QUAL, DocumentTypeCode.RETT, DocumentTypeCode.SCHR, DocumentTypeCode.SOZI, DocumentTypeCode.STUD,
    DocumentTypeCode.TRFU, DocumentTypeCode.TRPL, DocumentTypeCode.VERO, DocumentTypeCode.VERT, DocumentTypeCode.VIRO, DocumentTypeCode.WUND,];

    static readonly DocumentClassCodes = [DocumentClass.ADM, DocumentClass.ANF, DocumentClass.ASM, DocumentClass.AUS,
    DocumentClass.BEF, DocumentClass.BIL, DocumentClass.BRI, DocumentClass.DOK, DocumentClass.DUR, DocumentClass.FOR,
    DocumentClass.GUT, DocumentClass.LAB, DocumentClass.MED, DocumentClass.P570168, DocumentClass.PLA, DocumentClass.VER, DocumentClass.VID,];

    status = new StatusEventController(this);

    text?: string;

    fileInfo: Partial<StoreFileInfo> = {};
    @query("#upload")
    upload!: DruideUploadButton;
    @property({ type: Object })
    recordIdentifier!: RecordIdentifier;
    uploadPending = false;

    constructor(recordIdentifier: RecordIdentifier) {
        super();
        if (recordIdentifier) {
            this.recordIdentifier = recordIdentifier;
        }
    }

    private classificationToSelectOption(...classCodes: ClassificationCodeScheme[]): SelectOption[] {
        const options: SelectOption[] = [];
        for (const classCode of classCodes) {
            options.push({ id: classCode.getUrn(), title: classCode.name });
        }
        options.sort((o1, o2) => o1.title.localeCompare(o2.title));

        return options;
    }

    protected override render(): TemplateResult {
        return html`
            <div slot="content" .style=${CssConst.flexCenter}>
                <druide-card style="width:500px;" label="ePA Dokument hinzufügen">
                    <div class="form">
                        <druide-upload-button id="upload" @file-upload=${this.checkUploadType}></druide-upload-button>
                        <druide-text-field id="name" label="Titel" required maxLength="126"></druide-text-field>
                        <druide-select-field id="classCode" label="Grobe Klassifizierung" .value=${DocumentClass.DOK.getUrn()} .options=${this.classificationToSelectOption(...EpaUploadDocumentView.DocumentClassCodes)}></druide-select-field>
                        <druide-select-field id="typeCode" label="Art des Dokuments" .value=${DocumentTypeCode.BEFU.getUrn()} .options=${this.classificationToSelectOption(...EpaUploadDocumentView.DocumentTypeCodes)}></druide-select-field>
                        <druide-select-field id="confidentiality" label="Vertraulichkeitskennzeichnung" .value=${ConfidentialityCode.N.getUrn()} .options=${this.classificationToSelectOption(...EpaUploadDocumentView.ConfidentialityCodes)}></druide-select-field>
                        <loader-message class=${this.uploadPending ? "" : "hide"}></loader-message>
                    </div> 
                    <div slot="footer-left" style="align-self:center; font-size:0.85em;">${this.status.message}</div>
                    <druide-button id="save-button" slot="footer-right" @click=${this.storeDocument}>Dokument zu ePA Hinzufügen</druide-button>
                </druide-card>
            </div>
        `;
    }

    private findClassification<T extends ClassificationCodeScheme>(elementId: string, possibleValues: T[]): T {
        const valueUrn = this.shadowRoot?.querySelector<HTMLInputElement>("#" + elementId)?.value || "";
        return possibleValues.find(v => v.getUrn() === valueUrn)!;
    }

    private checkUploadType() {
        const type = this.getUploadType();
        if (!type) {
            this.upload.reset();
        } else {
            StatusEvent.log("");
        }
    }

    private getUploadType(): EpaMimeType | undefined {
        const allowedTypes = Object.keys(EpaMimeTypeName);
        let fileType = this.upload.fileInfo?.type.toLowerCase();
        const filename = this.upload.fileInfo?.name.toLowerCase();
        if (fileType) {
            if (fileType === "application/msword" && filename?.endsWith(".rtf")) {
                fileType = "text/rtf";
            }
            if (allowedTypes.indexOf(fileType) != -1) {
                return fileType as EpaMimeType;
            }
        }
        StatusEvent.log("Die ePA unterstützt dieses Dateiformat nicht.");
        return undefined;
    }

    private async storeDocument() {
        if (!this.uploadPending) {
            this.uploadPending = true;
            try {
                if (!this.upload.fileInfo || !this.upload.fileData) {
                    StatusEvent.log("Bitte wählen Sie eine Datei aus.");
                    return;
                }
                const mimeType = this.getUploadType();
                if (!mimeType) {
                    return;
                }

                const epaDefaults = {
                    healthcareFacilityType: HealthcareFacilityType.HEB,
                    practiceSetting: PracticeSettingCode.FRAU,
                    authorRole: AuthorRole.Behandler,
                };


                const fileInfo: StoreFileInfo = {
                    recordIdentifier: this.recordIdentifier,
                    filename: this.upload.fileInfo.name,
                    format: DocumentFormatCode.MIMETypeAbleitbar,
                    healthcareFacilityType: epaDefaults.healthcareFacilityType,
                    practiceSetting: epaDefaults.practiceSetting,
                    mimeType: mimeType,
                    name: this.shadowRoot?.querySelector<HTMLInputElement>("#name")?.value || "",

                    classCode: this.findClassification("classCode", EpaUploadDocumentView.DocumentClassCodes),
                    type: this.findClassification("typeCode", EpaUploadDocumentView.DocumentTypeCodes),
                    confidentiality: this.findClassification("confidentiality", EpaUploadDocumentView.ConfidentialityCodes),
                };

                const card = await TiPortal.tiSession.cardService.getCard(CardType.SMC_B);
                const telematikId = await TiPortal.tiSession.cardService.readTelematikIdFromCertificate(card!, CertRefEnum.C_AUT);
                const institute = new AuthorInstitution(card!.cardHolderName!, telematikId!);
                if (!institute) {
                    StatusEvent.log("Ihre Telematik-ID kann nicht bestimmt werden.");
                    return;
                }
                const connector = await TiPortal.tiSession.getConnector();
                const phrService = connector.getPhrService();

                const person = new AuthorPerson(TiPortal.tiSession.user.firstName, TiPortal.tiSession.user.lastName);
                const fileAuthor = new FileAuthor(person, institute, epaDefaults.authorRole);
                await phrService.storeDocument(fileInfo, fileAuthor, undefined, this.upload.fileData);
                StatusEvent.log("Dokument erfolgreich zu ePA hinzugefügt.");
                this.dispatchEvent(new CustomEvent("epa-reload", { bubbles: true, composed: true }));
            } finally {
                this.uploadPending = false;
            }
        }
    }


}

import { TiPortal } from "@/TiPortal";
import { KimLog } from "@yoshteq/ti365-ts-sdk";
import { css, html, LitElement, TemplateResult } from "lit";
import { customElement, state } from "lit/decorators.js";

@customElement("kim-reactivate")
export class KimReactivate extends LitElement {
  static styles = css`
    :host {
        box-sizing: border-box;
    }
`;

  @state()
  private pending: string | undefined = undefined;

  private async revokeDeregisterAccount() {
    const kimClient = await TiPortal.kimClient;
    try {
      this.pending = "Re-Aktivierung am KIM Fachdienst angefordert.";
      this.requestUpdate();
      await kimClient.reactivateAccount();
      location.hash = "#kim";
    } catch (stacktrace) {
      KimLog.error(stacktrace);
      this.pending = "Fehler bei der Re-Aktivierung des Accounts.";
      this.requestUpdate();
    }
  }

  protected override render(): TemplateResult {
    return html`
      ${this.pending ? this.renderPending() : this.renderRevokeDeregister()}
     
      `;
  }
  protected renderPending(): TemplateResult {
    return html`<druide-card label="Account re-aktivieren">
      <loader-message>${this.pending}</loader-message>
    </druide-card>`;
  }

  protected renderRevokeDeregister(): TemplateResult {
    return html`
     <druide-card label="Account re-aktivieren" @submit=${this.revokeDeregisterAccount} >
        Wenn Sie diesen Account innerhalb der letzten 30 Tage deaktiviert haben, können Sie diesen hier wieder aktivieren.
        <druide-button slot="footer-right"  submit>Account re-aktivieren</druide-button>
      </druide-card>`;
  }


}

import { Global } from "@/Global";
import { TiPortal } from "@/TiPortal";
import { EpaFileInfo } from "@yoshteq/ti365-ts-sdk";
import { css, html, LitElement, TemplateResult } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { CssConst } from "../Components/CssConst";
import { StatusEventController } from "../Components/StatusEventController";
import "./EpaSimpleListEntry";

@customElement("epa-mio-view")
export class EpaMioView extends LitElement {
    static styles = css`
    :host{
        --l-header-height: var(--header-height,  50px);
        font-size:0; // This is a hack that remove some spacing added around the iframe.
        position:relative;
    }
    div{
        font-size:1rem;
        position:absolute;
        top:10px;
    }
    iframe{
        width: 100%;
        box-sizing: border-box;
        height: calc(100vh - var(--l-header-height));
        margin: 0;
        border: 0;
        padding: 0;
    }
    `;
    status = new StatusEventController(this);
    @property({ type: Object })
    public epaFileInfo?: EpaFileInfo;
    blob?: Blob;

    @query("iframe")
    iframe!: HTMLIFrameElement;
    viewerReady: boolean = false;

    constructor(epaFileInfo?: EpaFileInfo) {
        super();
        if (epaFileInfo) {
            this.epaFileInfo = epaFileInfo;
        }
    }

    override connectedCallback(): void {
        super.connectedCallback();
        this.loadEpaData();

        window.addEventListener("message", (e) => this.handleMessage(e), {
            once: true
        });
    }
    private handleMessage(e: MessageEvent) {
        if (e.data === "mio-viewer-ready") {
            this.viewerReady = true;
            if (this.blob) {
                this.sendData();
            }
        }
    }
    private async sendData() {
        if (this.iframe.contentWindow && this.blob) {
            const xml = new TextDecoder().decode(await this.blob.arrayBuffer());
            this.iframe.contentWindow.postMessage(xml, "*");
        }
    }

    private async loadEpaData() {
        if (this.epaFileInfo) {
            const con = await TiPortal.tiSession.getConnector();
            this.blob = await con.getPhrService().getDocumentBlob(this.epaFileInfo);
            this.requestUpdate();
            if (this.viewerReady) {
                this.sendData();
            }
        }

    }

    protected override render(): TemplateResult {
        return html`
            <iframe src=${Global.mioViewerUrl}></iframe>
            ${this.blob ?
                html``
                : html`
            <div .style=${CssConst.flexCenter}>
                <druide-card  style="width:360px;" label="Lade ePA Dokument">
                    <loader-message>${this.status.message}</loader-message>
                </druide-card>
            </div>`

            }
            
        `;

    }
}

import "@yoshteq/druide-webcomponents";
import { css, html, LitElement, TemplateResult } from "lit";
import { customElement, query } from "lit/decorators.js";
import "./DruideDragDropUpload";
import { DruideDragDropUpload, UploadInfo, UploadInfoSuccess } from "./DruideDragDropUpload";
import "./LoaderSpinner";

@customElement("druide-upload-button")
export class DruideUploadButton extends LitElement {
    static styles = css``;
    fileInfo?: File;
    fileData?: ArrayBuffer | null;
    state: "open" | "loading" | "done" | "error" = "open";

    @query("druide-drag-drop-upload")
    dragDropUpload!: DruideDragDropUpload;

    private receiveFile(file: UploadInfo) {
        this.fileInfo = file.info;
        if (file.status === "pending") {
            this.state = "loading";
            this.fileData = undefined;
        } else if (file.status === "success") {
            const uploadInfo = file as UploadInfoSuccess;
            this.fileData = uploadInfo.data;
            this.state = "done";
            this.dispatchEvent(new Event("file-upload"));
        } else {
            this.fileData = undefined;
            this.state = "error";
        }
        this.requestUpdate();
    }

    protected override render(): TemplateResult {
        let buttonContent = html`<slot>Datei hochladen</slot>`;
        if (this.state === "error") {
            buttonContent = html`Fehler beim Upload`;
        } else if (this.state === "done") {
            buttonContent = html`${this.fileInfo?.name}`;
        } else if (this.state === "loading") {
            return html`<druide-button><loader-spinner color="white" style="height:20px;width:20px"></druide-button>`;
        }

        return html`<druide-button  @click=${() => this.dragDropUpload.openUploadFileDialog()} >${buttonContent}</druide-button>            
        <druide-modal id="drop-zone"><h1 style="color:white">Datei hochladen</h1></druide-modal>
        <druide-drag-drop-upload @file-upload=${(e) => this.receiveFile(e.detail)}></druide-drag-drop-upload>`;
    }

    public reset() {
        this.state = "open";
        this.fileData = undefined;
        this.fileInfo = undefined;
        this.requestUpdate();
    }
}


declare global {
    interface HTMLElementTagNameMap {
        "druide-upload-button": DruideUploadButton;
    }
}

import { ReactiveController, ReactiveControllerHost } from 'lit';

/**
 * Listens to the log event and populates the value to the message attribute.
 */
export class StatusEventController implements ReactiveController {
    host: ReactiveControllerHost;

    message: string = "";

    constructor(host: ReactiveControllerHost) {
        this.host = host;
        host.addController(this);
    }
    _logListener = (e) => {
        this.message = (e as CustomEvent).detail;
        this.host.requestUpdate();
    };
    hostConnected() {
        document.addEventListener("log", this._logListener);
    }
    hostDisconnected() {
        document.removeEventListener("log", this._logListener);
    }
}
import { LitElement, css, html } from "lit";
import { customElement, query } from "lit/decorators.js";

@customElement("stacked-navigation")
export default class StackedNavigation extends LitElement {
    static styles = css`
      :host {
        display: block;
        height:100%;
        width: 100%;
        display: flex;
      }

      div {
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        height: 100%;
        width: 100%;
      }

      nav {
        box-sizing: border-box;
      }

      nav#primaryNavigation {
        position: absolute;
        top: 0;
        left: 0;
        width: 46px;
        height: 100%;
        border-right: solid black 1px;
        z-index: 2;
        background-color: white;
      }

      nav#primaryNavigation:hover {
        width: 85%;
      }

      nav#primaryNavigation.active {
        width: 85%;
      }

      nav#secondaryNavigation {
        z-index: 1;
        width: calc(100% - 46px);
      }
    `;

    @query("#primaryNavigation")
    primaryNavigation!: HTMLElement;

    public showPrimaryNavigation() {
        this.primaryNavigation.classList.add("active");
    }
    public showSecondaryNavigation() {
        this.primaryNavigation.classList.remove("active");
    }

    protected render(): unknown {
        return html`
        <div>
          <nav id="primaryNavigation">
            <slot name="primaryNavigation"></slot>
          </nav>
          <nav id="secondaryNavigation">
            <slot name="secondaryNavigation"></slot>
          </nav>
        </div>
      `;
    }
}

import { DruideModal } from "@yoshteq/druide-webcomponents";
import { LitElement, TemplateResult, css, html } from "lit";
import { customElement, property, query } from "lit/decorators.js";

@customElement("druide-dialog")
export default class DruideDialog extends LitElement {
    static styles = css`
        .dialog {
            background-color: white;
            padding: 20px;
            border-radius: 5px;
            max-width: 600px;
            width: 90%;
        }
    `;

    @query("druide-modal")
    modal!: DruideModal;

    @property({ type: Boolean })
    shownOnStart: boolean = false;

    protected render(): TemplateResult {
        return html`
            <druide-modal ?visible=${this.shownOnStart} close-explicit>
                <div class="dialog">
                    <slot></slot>
                </div>
            </druide-modal>
        `;
    }

    public show() {
        this.modal.show();
    }

    public hide() {
        this.modal.hide();
    }
}
import { svgCardDown, svgMail } from "@/Components/SvgConst";
import { VzdEntry, VzdEntryKomLe } from "@yoshteq/ti365-ts-sdk";
import { Base64 } from "js-base64";
import { LitElement, TemplateResult, html, unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";
import style from "./VzdListEntry.css?inline";


@customElement("vzd-list-entry")
export default class VzdListEntry extends LitElement {
    static styles = [unsafeCSS(style)];

    @property({ attribute: false })
    vzdEntry?: VzdEntry;

    protected render(): TemplateResult {
        if (!this.vzdEntry) {
            return html``;
        }
        return html`
            <div class="list-entry">
                <div class="core-data-container">
                    <div>
                        <h3 class="name">${this.vzdEntry.displayName}</h3>
                        <div class="address">${this.buildAddressInformation()}</div>
                    </div>
                    <div>
                        ${this.vzdEntry.userCertificate.map((certificate) => this.renderCertificateDownloadButton(certificate))}
                    </div>
                </div>
                ${this.renderMails()}
            </div>
        `;
    }

    private buildAddressInformation() {
        if (this.vzdEntry?.street && this.vzdEntry.city) {
            return `${this.vzdEntry.street}, ${this.vzdEntry.postcode} ${this.vzdEntry.city}`;
        } else {
            return "Keine Adresse vorhanden";
        }
    }

    private renderCertificateDownloadButton(certificate: Uint8Array) {
        return html`<druide-icon-button .icon=${svgCardDown} @click=${() => this.downloadUserCertificate(certificate)}></druide-icon-button>`;
    }

    private renderMails() {
        if (this.vzdEntry!.mail?.length === 0) {
            return undefined;
        }
        return html`
            <div class="mail-container">
                <druide-icon class="mail-icon" .svg=${svgMail}></druide-icon>
                <div class="mail-list">
                    ${this.vzdEntry!.mail.map((entry) => this.buildMailInformation(entry))}
                </div>
            </div>
        `;
    }

    private buildMailInformation(mailEntry: VzdEntryKomLe) {
        return html`
            <div class="mail-entry" @click=${(e) => this.sendMail(mailEntry.mail)}>
                <div>${mailEntry.mail}</div>
            </div>
        `;
    }

    private downloadUserCertificate(certificate: Uint8Array) {
        var downloadElement = document.createElement("a");

        var b64encoded = Base64.fromUint8Array(certificate);

        downloadElement.href = `data:text/plain;base64,${b64encoded}`;
        downloadElement['download'] = "test.der";
        var clickEvent = new MouseEvent("click", {
            "view": window,
            "bubbles": true,
            "cancelable": false
        });
        downloadElement.dispatchEvent(clickEvent);
        downloadElement.remove();
    }

    private sendMail(mailAddress: string) {
        location.hash = `#kim/send/${mailAddress}`;
    }
}